import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { CompanyConnection, ID, UserCompany } from '@Libs/model';
import { Observable } from 'rxjs';
import { SuState } from '@WebUi/su/store/su.state';
import { ActiveCompanyService } from '@WebUi/dashboard/services/active-company.service';

@Injectable({
  providedIn: 'root',
})
export class SuActiveCompanyService extends ActiveCompanyService {

  @Select(SuState.activeSuCompanyId) readonly activeCompanyId$!: Observable<ID | null>;
  @Select(SuState.activeSuCompany) readonly activeCompany$!: Observable<UserCompany | null>;
  @Select(SuState.activeSuCompanyConnections) readonly activeCompanyConnections$!: Observable<CompanyConnection[] | null>;
  @Select(SuState.activeSuCompanyHasConnections) readonly activeCompanyHasConnections$!: Observable<boolean | null>;
  @Select(SuState.activeSuCompanyNotBrokenConnections) readonly activeCompanyNotBrokenConnections$!: Observable<CompanyConnection[] | null>;
  @Select(SuState.activeSuCompanyHasNotBrokenConnections) readonly activeCompanyHasNotBrokenConnections$!: Observable<boolean | null>;
  @Select(SuState.activeSuCompanyBrokenConnections) readonly activeCompanyBrokenConnections$!: Observable<CompanyConnection[] | null>;
  @Select(SuState.activeSuCompanyHasBrokenConnections) readonly activeCompanyHasBrokenConnections$!: Observable<boolean | null>;
  @Select(SuState.activeSuCompanyAccountingSystemConnection) readonly activeCompanyAccountingSystemConnection$!: Observable<CompanyConnection | null>;

}
