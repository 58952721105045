import { ChangeDetectionStrategy, Component, HostBinding, Inject, Injector, Input } from '@angular/core';
import { SvgIconAbstract, SvgIconsModuleOptions, SVG_ICONS_MODULE_OPTIONS_TOKEN } from '../svg-icons';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent extends SvgIconAbstract {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('icon') svgIconFileName!: string;

  @HostBinding('class') override cssClasses!: string;

  constructor(
    @Inject(SVG_ICONS_MODULE_OPTIONS_TOKEN) private svgIconsModuleOptions: SvgIconsModuleOptions,
    injector: Injector,
  ) {
    super(injector);
  }

  get className(): string {
    return this.svgIconsModuleOptions.classNameFactory(this.svgIconFileName);
  }

  get svgIconPath(): string {
    return this.svgIconsModuleOptions.svgIconPathFactory(this.svgIconFileName);
  }

}
