import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BlockingLoaderService } from '@WebUi/app/services/blocking-loader.service';
import { NonBlockingLoaderService } from '@WebUi/app/services/non-blocking-loader.service';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { APP_TITLE } from '@WebUi/helpers/injection-tokens';
import { Title } from '@angular/platform-browser';
import { FADE_ANIMATION_OPTIONS } from '@WebUi/helpers/animation';
import { SubscriptionsManagerDirective } from '@WebUi/shared/directives/subscriptions-manager.directive';
import { filter, takeUntil } from 'rxjs/operators';
import { SwUpdate, VersionEvent, VersionReadyEvent } from '@angular/service-worker';
import { DOCUMENT } from '@angular/common';
import { LoggingOutBlockingLoaderService } from '@WebUi/app/services/logging-out-blocking-loader.service';

@Component({
  selector: 'aeb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInOnEnterAnimation(FADE_ANIMATION_OPTIONS),
    fadeOutOnLeaveAnimation(FADE_ANIMATION_OPTIONS),
  ],
})
export class AppComponent extends SubscriptionsManagerDirective implements OnInit, AfterViewInit {

  // @ViewChild('promptAppUpdateDialog', {
  //   static: true,
  // }) promptAppUpdateDialogTemplate!: TemplateRef<unknown>;

  // promptAppUpdateDialogRef: DialogRef | null = null;

  // private readonly FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_TITLE) public appTitle: string,
    private titleService: Title,
    public blockingLoaderService: BlockingLoaderService,
    public nonBlockingLoaderService: NonBlockingLoaderService,
    public loggingOutBlockingLoaderService: LoggingOutBlockingLoaderService,
    private sw: SwUpdate,
  ) {
    super();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.appTitle);
  }

  ngAfterViewInit(): void {
    if (this.sw.isEnabled) {
      this.sw.versionUpdates
        .pipe(
          filter((event: VersionEvent): event is VersionReadyEvent => event.type === 'VERSION_READY'),
          takeUntil(this.destroyer$),
        )
        .subscribe(() => {
          // this.promptAppUpdateDialogRef = this.animatedDialog.open(this.promptAppUpdateDialogTemplate, {
          //   ariaLabel: 'New version is available',
          // });

          this.document.location.reload();
        });
    }
  }

  // onPromptAppUpdateModalCloseClick(): void {
  //   this.remindLater();
  // }

  // onPromptAppUpdateModalRemindLaterClick(): void {
  //   this.remindLater();
  // }

  // onPromptAppUpdateModalUpdateNowClick(): void {
  //   this.document.location.reload();

  //   this.promptAppUpdateDialogRef?.close();
  // }

  // private remindLater(): void {
  //   this.promptAppUpdateDialogRef?.close();

  //   timer(this.FIVE_MINUTES_IN_MILLISECONDS)
  //     .pipe(
  //       takeUntil(this.destroyer$),
  //     )
  //     .subscribe(() => {
  //       this.promptAppUpdateDialogRef = this.animatedDialog.open(this.promptAppUpdateDialogTemplate, {
  //         ariaLabel: 'New version is available',
  //       });
  //     });
  // }

}
