import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'aeb-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptModalComponent {

  @Input() heading!: string;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close: EventEmitter<void> = new EventEmitter();

  @ContentChild('content') readonly content!: TemplateRef<any>;
  @ContentChild('actions') readonly actions!: TemplateRef<any>;

  onCloseClick(): void {
    this.close.emit();
  }

}
