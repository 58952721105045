import { IAnimationOptions } from 'angular-animations/common/interfaces';

export const DEFAULT_ANIMATION_DURATION = 75;

export const DEFAULT_ANIMATION_OPTIONS: IAnimationOptions = {
  duration: DEFAULT_ANIMATION_DURATION,
};

export const FADE_ANIMATION_OPTIONS: IAnimationOptions = DEFAULT_ANIMATION_OPTIONS;

export const SLIDE_RIGHT_ANIMATION_OPTIONS = {
  duration: DEFAULT_ANIMATION_DURATION * 2,
};
