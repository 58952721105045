import { ExternalSystemTypeFeature, ExternalSystemTypeFeatureBookkepingMode } from './external-system';

export type VippsLedger = {
  handleId: string;
  bankAccountId: string;
  recipientHandlerType: VippsRecipientHandleType;
};

export enum VippsRecipientHandleType {
  ECOM = 0,
  VIPPSNUMMER = 1,
}

export const VIPPS_FEATURES_BY_RECIPIENT_HANDLE_TYPE_MAP: Record<VippsRecipientHandleType, number> = {
  [VippsRecipientHandleType.ECOM]: ExternalSystemTypeFeature.SALES_CHANNEL_BOOKKEEPING | ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING, //80
  [VippsRecipientHandleType.VIPPSNUMMER]: ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_BOOKKEEPING | ExternalSystemTypeFeature.TRANSACTION_TO_UNKNOWN_INVOICE_BOOKKEEPING | ExternalSystemTypeFeature.PAYOUT_TRANSACTIONS_SUMMARY_TOWARDS_INTERIM_ACCOUNT_BOOKKEEPING, //224
};

export function isVippsRecipientHandleTypeSupportsBookkeepingMode(type: VippsRecipientHandleType, bookkeepingMode: ExternalSystemTypeFeatureBookkepingMode): boolean {
  return !!(VIPPS_FEATURES_BY_RECIPIENT_HANDLE_TYPE_MAP[type] & bookkeepingMode);
};
