import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastsService } from '@Libs/toasts';
import { TranslateService } from '@ngx-translate/core';
import { ToastExpandableContentComponent } from '@WebUi/shared/components/toast-templates/expandable-content/expandable-content.component';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const SKIP_HTTP_SERVER_ERROR_INTERCEPTOR = new HttpContextToken(() => false);

@Injectable({
  providedIn: 'root',
})
export class HttpServerErrorInterceptor implements HttpInterceptor {

  constructor(
    private translateService: TranslateService,
    private toastsService: ToastsService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (request.context.get(SKIP_HTTP_SERVER_ERROR_INTERCEPTOR)) {
            return throwError(() => response);
          }

          if (response.status >= 500 && response.status < 600) {
            this.translateService.get('Toasts.HttpErrorResponse.5xx')
              .subscribe((translation: any) => {
                this.toastsService.error({
                  heading: translation['Heading'],
                  contentFactory: {
                    component: ToastExpandableContentComponent,
                    properties: {
                      content: translation['Content'],
                      expandableContent: JSON.stringify(response.error, undefined, 2),
                    },
                  },
                  autoclose: false,
                });
              });
          }

          return throwError(() => response);
        }),
      );
  }

}
