import { environment } from '@WebUi/env';
import { NgxsModuleOptions } from '@ngxs/store';

export const NGXS_MODULE_CONFIG: NgxsModuleOptions = {
  developmentMode: !environment.production,
  selectorOptions: {
    injectContainerState: false,
    suppressErrors: false,
  },
  compatibility: {
    strictContentSecurityPolicy: true,
  },
};

export const NGXS_MODULE_CONFIG_FOR_TESTS: NgxsModuleOptions = {
  developmentMode: true,
  selectorOptions: {
    injectContainerState: false,
    suppressErrors: false,
  },
  compatibility: {
    strictContentSecurityPolicy: true,
  },
};
