import { AnimationMetadata, AnimationOptions } from '@angular/animations';
import { DialogConfig } from '@angular/cdk/dialog';
import { BasePortalOutlet } from '@angular/cdk/portal';

export class AnimatedDialogConfig<D = unknown, R = unknown, C extends BasePortalOutlet = BasePortalOutlet> extends DialogConfig<D, R, C> {

  enterAnimation?: {
    animation: AnimationMetadata | AnimationMetadata[],
    options: AnimationOptions,
  };

  leaveAnimation?: {
    animation: AnimationMetadata | AnimationMetadata[],
    options: AnimationOptions,
  };

}
