import {
  SetupWizardOnboardingPaymentProviderFlowSnapshot,
  SetupWizardPaymentProviderFlowState,
  SetupWizardSalesChannelFlowState,
  SetupWizardOnboardingSalesChannelFlowSnapshot,
} from '@WebUi/setup-wizard/models/setup-wizard.model';

export type SetupWizardStateModel = {
  onboardingSnapshot: SetupWizardOnboardingSalesChannelFlowSnapshot;
  flowState: SetupWizardSalesChannelFlowState;
} | {
  onboardingSnapshot: SetupWizardOnboardingPaymentProviderFlowSnapshot;
  flowState: SetupWizardPaymentProviderFlowState;
} | {
  onboardingSnapshot: null;
  flowState: null;
};

export const SETUP_WIZARD_STATE_DEFAULTS: SetupWizardStateModel = {
  onboardingSnapshot: null,
  flowState: null,
};
