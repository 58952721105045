<div class="layout">
  <div class="container-fluid">
    <div class="row">
      <div class="layout-logo-wrapper">
        <picture>
          <img class="layout-logo img-fluid" src="/assets/logo.svg" width="354" height="50" [attr.alt]="appTitle">
        </picture>
      </div>

      <div class="layout-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div class="layout-powered-by" [innerHTML]="'Shared.PoweredBy' | translate:{poweredBy}"></div>
</div>
