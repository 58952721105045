import { ID, UserCompany } from '@Libs/model';
import { TransactionsCountSignalRMessagePayload, TvSettings } from '@WebUi/su/models/su.model';

export class SetActiveSuCompany {
  static readonly actionName = 'SetActiveSuCompany';
  static readonly type = '[SU] Set active SU company';

  constructor(public companyId: ID) { }
}

export class GetAllCompanies {
  static readonly actionName = 'GetAllCompanies';
  static readonly type = '[SU] Get all registered companies (short DTO)';
}

export class GetObservedCompany {
  static readonly actionName = 'GetObservedCompany';
  static readonly type = '[SU] Get observed company';

  constructor(public companyId: ID) { }
}

export class AddObservedCompany {
  static readonly actionName = 'AddObservedCompany';
  static readonly type = '[SU] Add observed company';

  constructor(public observedCompany: UserCompany) { }
}

export class UpdateObservedCompany {
  static readonly actionName = 'UpdateObservedCompany';
  static readonly type = '[SU] Update observed company';

  constructor(public observedCompany: UserCompany) { }
}

export class DeleteObservedCompany {
  static readonly actionName = 'DeleteObservedCompany';
  static readonly type = '[SU] Delete observed company';

  constructor(public companyId: ID) { }
}

export class ResetObservedCompanies {
  static readonly actionName = 'ResetObservedCompanies';
  static readonly type = '[SU] Reset observed companies';
}

export class TransactionsCountSignal {
  static readonly actionName = 'TransactionsCountSignal';
  static readonly type = '[SU] Transactions count signal';

  constructor(public payload: TransactionsCountSignalRMessagePayload) { }
}

export class StoreTvSettings {
  static readonly actionName = 'StoreTvSettings';
  static readonly type = '[SU] Store TV settings';

  constructor(public tvSettings: TvSettings) { }
}
