import { ID } from '../common';
import { gql } from 'apollo-angular';
import { Company, CompanyConnection } from '../company';
import { UserCompany } from '../user';
import {
  CompanyBaseFragment,
  CompanyBillingAddressFragment,
  UserCompanyFragment,
  CompanyConnectionFragment,
  CompanyContactPersonFragment,
} from './fragments';

export const CREATE_COMPANY = gql`
  mutation CreateCompany($company: CompanyInput!) {
    createCompany(company: $company) {
      ...UserCompanyFragment
    }
  }
  ${UserCompanyFragment}
`;

export type CreateCompanyResponse = {
  createCompany: UserCompany;
}

export type CreateCompanyVariables = {
  company: {
    companyNumber: string;
    name: string;
    billingAddress: {
      address1: string;
      countryCode: string;
      postalArea: string;
      postalCode: string;
    };
    contactPerson: {
      email: string;
      name: string;
      phone: string;
    };
    invoiceRecipientEmail: string;
  };
}


export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($companyId: ID!, $companyName: String!, $companyNumber: String!, $billingAddress: AddressInput!, $contactPerson: ContactInfoInput!, $invoiceRecipientEmail: String!) {
    updateCompany(companyId: $companyId, companyName: $companyName, companyNumber: $companyNumber, billingAddress: $billingAddress, contactPerson: $contactPerson, invoiceRecipientEmail: $invoiceRecipientEmail) {
      ...CompanyBaseFragment
      billingAddress {
        ...CompanyBillingAddressFragment
      }
      contactPerson {
        ...CompanyContactPersonFragment
      }
      invoiceRecipientEmail
    }
  }
  ${CompanyBaseFragment}
  ${CompanyBillingAddressFragment}
  ${CompanyContactPersonFragment}
`;

export type UpdateCompanyResponse = {
  updateCompany: Company;
}

export type UpdateCompanyVariables = {
  companyId: ID;
  companyName: string;
  companyNumber: string;
  billingAddress: {
    address1: string;
    countryCode: string;
    postalArea: string;
    postalCode: string;
  };
  contactPerson: {
    email: string;
    name: string;
    phone: string;
  };
  invoiceRecipientEmail: string;
}


export const DELETE_COMPANY = gql`
  mutation DeleteCompany($companyId: ID!) {
    deleteCompany(companyId: $companyId)
  }
`;

export type DeleteCompanyResponse = {
  deleteCompany: boolean;
}

export type DeleteCompanyVariables = {
  companyId: ID;
}


export const REVOKE_COMPANY_ACCESS = gql`
  mutation RevokeCompanyAccess($companyId: ID!, $userId: ID!) {
    revokeCompanyAccess(companyId: $companyId, userId: $userId)
  }
`;

export type RevokeCompanyAccessResponse = {
  revokeCompanyAccess: boolean;
}

export type RevokeCompanyAccessVariables = {
  companyId: ID;
  userId: ID;
}


export const COMPLETE_COMPANY_CONNECTION = gql`
  mutation CompleteCompanyConnection($companyId: ID!, $connectionToken: String!, $externalSystemId: String!) {
    completeCompanyConnection(companyId: $companyId, connectionToken: $connectionToken, externalSystemId: $externalSystemId) {
      ...CompanyConnectionFragment
    }
  }
  ${CompanyConnectionFragment}
`;

export type CompleteCompanyConnectionResponse = {
  completeCompanyConnection: CompanyConnection;
}

export type CompleteCompanyConnectionVariables = {
  companyId: ID;
  connectionToken: string;
  externalSystemId: string;
}


export const CREATE_COMPANY_CONNECTION = gql`
  mutation CreateCompanyConnection($companyId: ID!, $externalSystemId: String!, $externalSystemTypeId: String!, $connectionData: Object!, $token: String = null) {
    createCompanyConnection(companyId: $companyId, externalSystemID: $externalSystemId, externalSystemTypeId: $externalSystemTypeId, connectionData: $connectionData, token: $token) {
      ...CompanyConnectionFragment
    }
  }
  ${CompanyConnectionFragment}
`;

export type CreateCompanyConnectionResponse = {
  createCompanyConnection: CompanyConnection;
}

export type CreateCompanyConnectionVariables = {
  companyId: ID;
  externalSystemId: string;
  externalSystemTypeId: string;
  connectionData: unknown;
  token: string | null;
}


export const UPDATE_COMPANY_CONNECTION = gql`
  mutation UpdateCompanyConnection($companyId: ID!, $connectionId: ID!, $connectionData: Object!) {
    updateCompanyConnection(companyId: $companyId, connectionId: $connectionId, connectionData: $connectionData) {
      ...CompanyConnectionFragment
    }
  }
  ${CompanyConnectionFragment}
`;

export type UpdateCompanyConnectionResponse = {
  updateCompanyConnection: CompanyConnection;
}

export type UpdateCompanyConnectionVariables = {
  companyId: ID;
  connectionId: ID;
  connectionData: unknown;
}


export const DELETE_COMPANY_CONNECTION = gql`
  mutation DeleteCompanyConnection($connectionId: ID!) {
    deleteCompanyConnection(connectionId: $connectionId)
  }
`;

export type DeleteCompanyConnectionResponse = {
  deleteCompanyConnection: boolean;
}

export type DeleteCompanyConnectionVariables = {
  connectionId: ID;
}
