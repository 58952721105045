import { ExternalSystemToConnect, ExternalSystemToConnectIntention } from '@WebUi/connections/models/connections.model';

export class StoreExternalSystemToConnect {
  static readonly actionName = 'StoreExternalSystemToConnect';
  static readonly type = '[Connections] Store external system to connect';

  constructor(public externalSystem: ExternalSystemToConnect) { }
}

export class ResetExternalSystemToConnect {
  static readonly actionName = 'ResetExternalSystemToConnect';
  static readonly type = '[Connections] Reset external system to connect';
}

export class StoreExternalSystemToConnectIntention {
  static readonly actionName = 'StoreExternalSystemToConnectIntention';
  static readonly type = '[Connections] Store external system to connect intention';

  constructor(public externalSystem: ExternalSystemToConnectIntention) { }
}

export class ResetExternalSystemToConnectIntention {
  static readonly actionName = 'ResetExternalSystemToConnectIntention';
  static readonly type = '[Connections] Reset external system to connect intention';
}
