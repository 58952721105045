import {
  DateRangeOption,
  InvoiceReportListRequest,
  SortDirectionType,
  InvoiceReportSortKeyType,
} from '@Libs/model';

export const DEFAULT_INVOICE_REPORT_SORT_DIRECTION_TYPE = SortDirectionType.DESC;

export const DEFAULT_INVOICE_REPORT_SORT_KEY_TYPE = InvoiceReportSortKeyType.DATE_UTC;

export const INITIAL_INVOICES_AMOUNT = 50;

export const LOAD_MORE_INVOICES_AMOUNT = 50;


export type InvoiceReportListOptions = InvoiceReportListRequest & {
  dateRangeOption: DateRangeOption | null;
}
