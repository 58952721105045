import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormLabelComponent } from '@WebUi/shared/standalone-components/form-label/form-label.component';
import { FormControlComponent } from '@WebUi/shared/standalone-components/form-control/form-control.component';
import { FormDescriptionComponent } from '@WebUi/shared/standalone-components/form-description/form-description.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormLabelComponent,
    FormControlComponent,
    FormDescriptionComponent,
  ],
  selector: 'aeb-form-line',
  templateUrl: './form-line.component.html',
  styleUrls: ['./form-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLineComponent { }
