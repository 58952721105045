import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@Libs/model';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ActiveCompanyService } from '@WebUi/dashboard/services/active-company.service';

// true - send active company id
// false - do not send company id
// string - send provided company id from context
export const XEBRIDGE_COMPANY_ID = new HttpContextToken<ID | boolean>(() => true);

@Injectable()
export class XEBridgeCompanyIdInterceptor implements HttpInterceptor {

  private readonly MUTATIONS_EXCEPTIONS: string[] = [
    'CreateCompany',
    'CreateCompanyConnection',
    'GetAllCompanies',
    'GetUser',
    'GetUserCompanies',
  ];

  constructor(private activeCompanyService: ActiveCompanyService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const xebridgeCompanyId: ID | boolean = request.context.get(XEBRIDGE_COMPANY_ID);

    if (xebridgeCompanyId === false) {
      return next.handle(request);
    }

    if (typeof xebridgeCompanyId === 'string') {
      const modifiedRequest: HttpRequest<any> = request.clone({
        headers: request.headers.set('X-EBridge-CompanyID', xebridgeCompanyId),
      });

      return next.handle(modifiedRequest);
    }

    // AF-77
    // Do not send X-EBridge-CompanyID header with some company manager's mutations which do not rely on companyId
    // This workaround prevents getting 403 Forbidden response from the gateway
    if (request.body?.operationName && this.MUTATIONS_EXCEPTIONS.includes(request.body.operationName)) {
      return next.handle(request);
    }

    return this.activeCompanyService.activeCompanyId$
      .pipe(
        take(1),
        switchMap((activeCompanyId: ID | null) => {
          if (!activeCompanyId) {
            return next.handle(request);
          }

          const modifiedRequest: HttpRequest<any> = request.clone({
            headers: request.headers.set('X-EBridge-CompanyID', activeCompanyId),
          });

          return next.handle(modifiedRequest);
        }),
      );
  }

}
