import { Injectable } from '@angular/core';
import { Lang } from '@Libs/model';
import { AppState } from '@WebUi/app/store/app.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  @Select(AppState.lang) readonly lang$!: Observable<Lang>;

}
