import { HttpBackend, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, finalize, map, shareReplay, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsService {

  private cache: Map<string, string | null> = new Map();
  private cacheObservable: Map<string, Observable<string | null>> = new Map();

  constructor(private httpBackend: HttpBackend) { }

  getIcon(svgIconPath: string): Observable<string | null> {
    if (this.cache.has(svgIconPath)) {
      return of(this.cache.get(svgIconPath) as string | null);
    }

    if (this.cacheObservable.has(svgIconPath)) {
      return this.cacheObservable.get(svgIconPath) as Observable<string | null>;
    }

    const observableToCache: Observable<string | null> = this.httpBackend.handle(new HttpRequest<string>(
      'GET',
      svgIconPath,
      {
        responseType: 'text',
      },
    ))
      .pipe(
        filter((httpEvent: HttpEvent<string>): httpEvent is HttpResponse<string> => httpEvent.type === HttpEventType.Response),
        map((httpResponse: HttpResponse<string>) => httpResponse.body),
        shareReplay(1),
      );

    this.cacheObservable.set(svgIconPath, observableToCache);

    observableToCache
      .pipe(
        take(1),
        finalize(() => {
          // Remove observable from cache in any scenario (success, error, complete)
          this.cacheObservable.delete(svgIconPath);
        }),
      )
      .subscribe((svgIcon: string | null) => {
        this.cache.set(svgIconPath, svgIcon);
      });

    return observableToCache;
  }

}
