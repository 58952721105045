import { gql } from 'apollo-angular';

export const UserBaseFragment = gql`
  fragment UserBaseFragment on User {
    id
    fullName
    email
  }
`;

export const CompanyBaseFragment = gql`
  fragment CompanyBaseFragment on Company {
    id
    name
    companyNumber
  }
`;

export const CompanyBillingAddressFragment = gql`
  fragment CompanyBillingAddressFragment on Address {
    address1
    countryCode
    postalArea
    postalCode
  }
`;

export const CompanyContactPersonFragment = gql`
  fragment CompanyContactPersonFragment on ContactInfo {
    email
    name
    phone
  }
`;

export const CompanyInfoFragment = gql`
  fragment CompanyInfoFragment on CompanyInfo {
    id
    name
    companyNumber
    contactPerson {
      ...CompanyContactPersonFragment
    }
  }
  ${CompanyContactPersonFragment}
`;

export const CompanyUserFragment = gql`
  fragment CompanyUserFragment on CompanyUser {
    companyId
    userId
    email
    fullName
    role
  }
`;

export const CompanyConnectionFragment = gql`
  fragment CompanyConnectionFragment on CompanyConnection {
    id
    externalSystemTypeId
    externalSystemId
    state
    stateMessage
    connectionData
  }
`;

export const UserCompanyFragment = gql`
  fragment UserCompanyFragment on Company {
    ...CompanyBaseFragment
    invoiceRecipientEmail
    billingAddress {
      ...CompanyBillingAddressFragment
    }
    contactPerson {
      ...CompanyContactPersonFragment
    }
    users {
      ...CompanyUserFragment
    }
    connections {
      ...CompanyConnectionFragment
    }
  }
  ${CompanyBaseFragment}
  ${CompanyBillingAddressFragment}
  ${CompanyContactPersonFragment}
  ${CompanyUserFragment}
  ${CompanyConnectionFragment}
`;

export const UserFragment = gql`
  fragment UserFragment on User {
    ...UserBaseFragment
    companies {
      ...UserCompanyFragment
    }
  }
  ${UserBaseFragment}
  ${UserCompanyFragment}
`;

export const InvoiceReportFragment = gql`
  fragment InvoiceReportFragment on InvoiceReport {
    accountingInvoiceId
    accountingInvoiceNumber
    accountingSystemId
    accountingSystemType
    companyId
    currencyCode
    customerName
    dateUtc
    dueDateUtc
    id
    originDocumentId
    originSystemId
    originSystemType
    originOrderId
    originOrderNumber
    status
    statusMessage
    sumInclVat
  }
`;
