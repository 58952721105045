import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { NonBlockingLoaderService } from '@WebUi/app/services/non-blocking-loader.service';
import { DEFAULT_AUTHORITY } from '@WebUi/app/models/app.model';
import { AuthService } from '@WebUi/app/services/auth.service';

@Component({
  selector: 'aeb-dev-startup',
  templateUrl: './dev-startup.component.html',
  styleUrls: ['./dev-startup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevStartupComponent {

  disabledLoginButton = false;

  constructor(
    public authService: AuthService,
    private nonBlockingLoaderService: NonBlockingLoaderService,
  ) { }

  @HostListener('window:pagehide', ['$event'])
  onPageHide(event: PageTransitionEvent): void {
    // Fix issue with Back/Forward cache
    // https://developer.chrome.com/blog/page-lifecycle-api/
    // https://web.dev/bfcache/
    // The page isn't being discarded, so it can be reused later (for example after 'Back' navigation button click in the browser)
    if (event.persisted && this.disabledLoginButton) {
      this.disabledLoginButton = false;

      this.nonBlockingLoaderService.stop();
    }
  }

  onLoginClick(): void {
    this.disabledLoginButton = true;

    this.nonBlockingLoaderService.start();

    // TODO There are no language chooser on the page? Should we provide it for user and pass it to AD B2C?
    this.authService.login({
      authority: DEFAULT_AUTHORITY,
      redirectStartPage: '/dashboard',
      prompt: 'login',
      extraQueryParameters: {
        'mode': 'dev',
      },
    })
      .subscribe();
  }

}
