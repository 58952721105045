<ng-container *ngIf="toast">
  <div class="toast-status">
    <ng-container [ngSwitch]="toast.status">
      <ng-container *ngSwitchCase="ToastStatus.INFO">
        <heroicon icon="information-circle" type="solid"></heroicon>
      </ng-container>

      <ng-container *ngSwitchCase="ToastStatus.SUCCESS">
        <heroicon icon="check-circle" type="solid"></heroicon>
      </ng-container>

      <ng-container *ngSwitchCase="ToastStatus.WARNING">
        <heroicon icon="exclamation" type="solid"></heroicon>
      </ng-container>

      <ng-container *ngSwitchCase="ToastStatus.ERROR">
        <heroicon icon="exclamation" type="solid"></heroicon>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="toast.heading">
    <div class="toast-heading">{{ toast.heading }}</div>
  </ng-container>

  <ng-container *ngIf="toast.showCloseButton === 'auto' && !toast.autoclose">
    <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
  </ng-container>

  <ng-container *ngIf="toast.showCloseButton === true">
    <ng-container [ngTemplateOutlet]="closeBtn"></ng-container>
  </ng-container>

  <ng-container *ngIf="toast.contentFactory">
    <div class="toast-content">
      <ng-template #content></ng-template>
    </div>
  </ng-container>

  <ng-container *ngIf="toast.autoclose">
    <div class="toast-autoclose-progress" #autocloseProgress>
    </div>
  </ng-container>
</ng-container>

<ng-template #closeBtn>
  <div class="toast-close">
    <button type="button" class="toast-close-btn" title="Close" (click)="onCloseClick()">
      <heroicon icon="x" type="solid"></heroicon>
    </button>
  </div>
</ng-template>
