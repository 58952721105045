import { Injectable } from '@angular/core';
import {
  ExternalSystem,
  ExternalSystemKind,
  ExternalSystemType,
  ExternalSystemTypeKind,
} from '@Libs/model';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  @Select(DashboardState.externalSystemTypeKinds) readonly externalSystemTypeKinds$!: Observable<ExternalSystemTypeKind[] | null>;
  @Select(DashboardState.externalSystemTypes) readonly externalSystemTypes$!: Observable<ExternalSystemType[] | null>;
  @Select(DashboardState.externalSystemTypeKindById) readonly externalSystemTypeKindById$!: Observable<(arg: ExternalSystemKind) => ExternalSystemTypeKind | null>;
  @Select(DashboardState.externalSystemTypeById) readonly externalSystemTypeById$!: Observable<(arg: ExternalSystem) => ExternalSystemType | null>;
  @Select(DashboardState.externalSystemTypesByKinds) readonly externalSystemTypesByKinds$!: Observable<(arg: ExternalSystemKind[]) => ExternalSystemType[] | null>;
  @Select(DashboardState.externalSystemTypesByKind) readonly externalSystemTypesByKind$!: Observable<(arg: ExternalSystemKind) => ExternalSystemType[] | null>;

  trackByExternalSystemTypeId(index: number, externalSystemType: ExternalSystemType): ExternalSystem {
    return externalSystemType.id;
  }

  trackByExternalSystemTypeKindId(index: number, externalSystemTypeKind: ExternalSystemTypeKind): ExternalSystemKind {
    return externalSystemTypeKind.id;
  }

}
