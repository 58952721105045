import { DialogModule } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';
import { AnimatedDialog } from './animated-dialog';

@NgModule({
  imports: [
    DialogModule,
  ],
  exports: [
    DialogModule,
  ],
  providers: [
    AnimatedDialog,
  ],
})
export class AnimatedDialogModule { }
