import { HttpBackend, HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@WebUi/env';
import { catchError, Observable, throwError } from 'rxjs';
import { Locale, Agreement, ExternalSystemTypeFeatureBillingable, ID, CompanyAgreementRequest } from '@Libs/model';
import { XEBRIDGE_COMPANY_ID } from '@WebUi/app/interceptors/x-ebridge-companyid.interceptor';
import { RollbarService } from '@WebUi/app/services/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class BillingApiService {

  // Clean http client without interceptors
  private cleanHttpClient: HttpClient;

  constructor(
    private httpClient: HttpClient,
    private httpBackend: HttpBackend,
    private rollbarService: RollbarService,
  ) {
    this.cleanHttpClient = new HttpClient(this.httpBackend);
  }

  fetchAgreementById(agreementId: number, localeId?: Locale): Observable<Agreement> {
    let params: HttpParams = new HttpParams();

    params = params.append('includeContent', 'true');

    if (localeId) {
      params = params.append('localeId', localeId);
    }

    return this.cleanHttpClient.get<Agreement>(`${environment.billingService.public}/agreements/${agreementId}`, {
      params,
    });
  }

  fetchAgreementByConstrains(feature: ExternalSystemTypeFeatureBillingable, externalSystemTypes: string, localeId?: Locale): Observable<Agreement> {
    let params: HttpParams = new HttpParams();

    params = params.append('feature', feature.toString());

    if (externalSystemTypes) {
      params = params.append('externalSystemTypes', externalSystemTypes);
    }

    params = params.append('includeContent', 'true');

    if (localeId) {
      params = params.append('localeId', localeId);
    }

    return this.cleanHttpClient.get<Agreement>(`${environment.billingService.public}/agreements`, {
      params,
    });
  }

  postCompanyAgreement(companyId: ID, payload: CompanyAgreementRequest): Observable<void> {
    return this.httpClient.post<void>(`${environment.billingService.private}/company/agreements`, payload, {
      context: new HttpContext().set(XEBRIDGE_COMPANY_ID, companyId),
    })
      .pipe(
        catchError((error: object) => {
          this.rollbarService.error('Add agreement crash', error);

          return throwError(() => error);
        }),
      );
  }

}
