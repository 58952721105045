import { ChangeDetectionStrategy, Component } from '@angular/core';
import { collapseOnLeaveAnimation, expandOnEnterAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { Toast } from '../toasts';
import { ToastsService } from '../toasts.service';

@Component({
  selector: 'toasts',
  templateUrl: './toasts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInOnEnterAnimation({
      duration: 150,
    }),
    fadeOutOnLeaveAnimation({
      duration: 150,
    }),
    expandOnEnterAnimation({
      duration: 150,
    }),
    collapseOnLeaveAnimation({
      duration: 150,
    }),
  ],
})
export class ToastsComponent {

  constructor(public toasts: ToastsService) { }

  trackByToastId(index: number, toast: Toast): string {
    return toast.id;
  }

}
