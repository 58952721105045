import {
  AccountingSystem,
  ClearhausCredentialsSettings,
  ID,
  KlarnaCredentialsSettings,
  NetsCredentialsSettings,
  PaymentProvider,
  PaypalCredentialsSettings,
  SalesChannel,
  StripeCredentialsSettings,
  SveaCredentialsSettings,
  VippsLedger,
} from '@Libs/model';
import {
  OnboardingOption,
  SupportedOnboardingFeatureForPaymentProvider,
  SupportedOnboardingFeatureForSalesChannel,
  SupportedOnboardingPaymentProvider,
} from '@WebUi/onboarding/models/onboarding.model';
import { FlowStepState } from '@WebUi/shared/models/flow-steps';
import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef, EventEmitter } from '@angular/core';

export type SetupWizardOnboardingSnapshot = SetupWizardOnboardingSalesChannelFlowSnapshot | SetupWizardOnboardingPaymentProviderFlowSnapshot;

export type SetupWizardFlowState = SetupWizardSalesChannelFlowState | SetupWizardPaymentProviderFlowState;

export type SetupWizardOnboardingSalesChannelFlowSnapshot = SetupWizardOnboardingUnimicroNettbutikkFlowSnapshot | SetupWizardOnboardingShopifyFlowSnapshot;

export function isSalesChannelFlowSnapshot(snapshot: SetupWizardOnboardingSnapshot): snapshot is SetupWizardOnboardingSalesChannelFlowSnapshot {
  return !!snapshot && snapshot.option === OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE;
}

export type SetupWizardOnboardingUnimicroNettbutikkFlowSnapshot = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE;
  feature: SupportedOnboardingFeatureForSalesChannel;
  agreementId: number;
  companyId: ID;
  accountingSystem: AccountingSystem;
  accountingSystemConnectionId: ID;
  salesChannel: SalesChannel.UNIMICRO_NETTBUTIKK;
  salesChannelConnectionId: null;
  hasEstore: boolean;
};

export type SetupWizardOnboardingShopifyFlowSnapshot = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE;
  feature: SupportedOnboardingFeatureForSalesChannel;
  agreementId: number;
  companyId: ID;
  accountingSystem: AccountingSystem;
  accountingSystemConnectionId: ID;
  salesChannel: SalesChannel.SHOPIFY;
} & ({
  salesChannelConnectionId: ID;
  hasEstore: true;
} | {
  salesChannelConnectionId: null;
  hasEstore: false;
});

export function isUnimicroNettbutikkFlowSnapshot(snapshot: SetupWizardOnboardingSnapshot): snapshot is SetupWizardOnboardingUnimicroNettbutikkFlowSnapshot {
  return isSalesChannelFlowSnapshot(snapshot) && snapshot.salesChannel === SalesChannel.UNIMICRO_NETTBUTIKK;
}

export function isShopifyFlowSnapshot(snapshot: SetupWizardOnboardingSnapshot): snapshot is SetupWizardOnboardingShopifyFlowSnapshot {
  return isSalesChannelFlowSnapshot(snapshot) && snapshot.salesChannel === SalesChannel.SHOPIFY;
}

export type SetupWizardOnboardingPaymentProviderFlowSnapshot = {
  option: OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER;
  feature: SupportedOnboardingFeatureForPaymentProvider;
  agreementId: number;
  companyId: ID;
  accountingSystem: AccountingSystem;
  accountingSystemConnectionId: ID;
  paymentProvider: SupportedOnboardingPaymentProvider;
};

export function isPaymentProviderFlowSnapshot(snapshot: SetupWizardOnboardingSnapshot): snapshot is SetupWizardOnboardingPaymentProviderFlowSnapshot {
  return !!snapshot && snapshot.option === OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER;
}

export function isVippsShapedFlowSnapshot(snapshot: SetupWizardOnboardingSnapshot): snapshot is SetupWizardOnboardingPaymentProviderFlowSnapshot {
  return isPaymentProviderFlowSnapshot(snapshot) && snapshot.paymentProvider === PaymentProvider.VIPPS;
}

export function isNotVippsShapedFlowSnapshot(snapshot: SetupWizardOnboardingSnapshot): snapshot is SetupWizardOnboardingPaymentProviderFlowSnapshot {
  return !isVippsShapedFlowSnapshot(snapshot);
}

export type SetupWizardSalesChannelFlowState = {
  step1: {
    startOrderProcessingDateUtc: number | null;
    state: FlowStepState;
  };
  step2: {
    paymentProviderConnectionExternalSystemIds: string[];
    state: FlowStepState;
  };
  step3: {
    state: FlowStepState;
  };
};

export type SetupWizardPaymentProviderFlowState = {
  step1: {
    connectionId: ID | null;
    state: FlowStepState;
  };
  step2: {
    state: FlowStepState;
  };
};

export function isSalesChannelFlowState(state: SetupWizardFlowState): state is SetupWizardSalesChannelFlowState {
  return 'startOrderProcessingDateUtc' in state.step1;
}

export function isPaymentProviderFlowState(state: SetupWizardFlowState): state is SetupWizardPaymentProviderFlowState {
  return 'connectionId' in state.step1;
}

export const SETUP_WIZARD_SALES_CHANNEL_FLOW_STATE_DEFAULTS: SetupWizardSalesChannelFlowState = {
  step1: {
    startOrderProcessingDateUtc: null,
    state: FlowStepState.UNTOUCHED,
  },
  step2: {
    paymentProviderConnectionExternalSystemIds: [],
    state: FlowStepState.UNTOUCHED,
  },
  step3: {
    state: FlowStepState.UNTOUCHED,
  },
};

export const SETUP_WIZARD_PAYMENT_VENDOR_FLOW_STATE_DEFAULTS: SetupWizardPaymentProviderFlowState = {
  step1: {
    connectionId: null,
    state: FlowStepState.UNTOUCHED,
  },
  step2: {
    state: FlowStepState.UNTOUCHED,
  },
};

export type CredentialsSettingsHarmony =
  {
    externalSystem: PaymentProvider.CLEARHAUS;
    credentials: ClearhausCredentialsSettings;
  } | {
    externalSystem: PaymentProvider.KLARNA;
    credentials: KlarnaCredentialsSettings;
  } | {
    externalSystem: PaymentProvider.NETS;
    credentials: NetsCredentialsSettings;
  } | {
    externalSystem: PaymentProvider.PAYPAL;
    credentials: PaypalCredentialsSettings;
  } | {
    externalSystem: PaymentProvider.STRIPE;
    credentials: StripeCredentialsSettings;
  } | {
    externalSystem: PaymentProvider.SVEA;
    credentials: SveaCredentialsSettings;
  } | {
    externalSystem: PaymentProvider.VIPPS;
    credentials: VippsLedger;
  };

export type NullableCredentialsSettingsHarmony =
  {
    externalSystem: PaymentProvider.CLEARHAUS;
    credentials: ClearhausCredentialsSettings | null;
  } | {
    externalSystem: PaymentProvider.KLARNA;
    credentials: KlarnaCredentialsSettings | null;
  } | {
    externalSystem: PaymentProvider.NETS;
    credentials: NetsCredentialsSettings | null;
  } | {
    externalSystem: PaymentProvider.PAYPAL;
    credentials: PaypalCredentialsSettings | null;
  } | {
    externalSystem: PaymentProvider.STRIPE;
    credentials: StripeCredentialsSettings | null;
  } | {
    externalSystem: PaymentProvider.SVEA;
    credentials: SveaCredentialsSettings | null;
  } | {
    externalSystem: PaymentProvider.VIPPS;
    credentials: VippsLedger | null;
  };

export type ConfiguredPaymentProviderConnectionCandidate = CredentialsSettingsHarmony & {
  connectionName: string;
  markedForCreation: true;
};

export type UnconfiguredPaymentProviderConnectionCandidate = {
  connectionName: string;
  markedForCreation: true;
  externalSystem: PaymentProvider;
  credentials: null;
};

export type PaymentProviderConnectionVariant = NullableCredentialsSettingsHarmony & {
  connectionName: null;
  markedForCreation: false;
};

export type PaymentProviderConnectionConfiguration = ConfiguredPaymentProviderConnectionCandidate | UnconfiguredPaymentProviderConnectionCandidate | PaymentProviderConnectionVariant;

export interface DynamicModalRef<C> {
  readonly overlayRef: OverlayRef;
  readonly componentRef: ComponentRef<C>;
};

export type ModalCredentialsInputContract<C, D> = {
  credentials: C | null;
  data: D;
};

export interface ModalCredentialsFrontEnd<C> {
  readonly skipClick: EventEmitter<void>;
  readonly closeClick: EventEmitter<void>;
  readonly continueClick: EventEmitter<C>;
}
