import { AccountingSystem } from './external-system';

export type UnimicroPlatformCompanyDescriptor = {
  Name: string;
  Key: string;
  OrganizationNumber: string;
};

export type UnimicroPlatformCompany = {
  CompanyName: string;
  OrganizationNumber: string;
  DefaultAddress: UnimicroPlatformAddress | null;
};

export type UnimicroPlatformAddress = {
  AddressLine1: string | null;
  AddressLine2: string | null;
  Country: string | null;
  CountryCode: string | null;
  City: string | null;
  PostalCode: string | null;
};

export enum UnimicroPlatformDomain {
  UNIMICRO = 'unimicro',
  DNB_REGNSKAP = 'dnb-regnskap',
  SPARE_BANK_1_REGNSKAP = 'sr-bank-regnskap',
  EIKA_REGNSKAP = 'eika-regnskap',
  TEST_UNIMICRO = 'test-unimicro',
}

export const UNIMICRO_PLATFORM_DOMAINS: UnimicroPlatformDomain[] = Object.values(UnimicroPlatformDomain);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isUnimicroPlatformDomain(unimicroPlatformDomain: any): unimicroPlatformDomain is UnimicroPlatformDomain {
  return UNIMICRO_PLATFORM_DOMAINS.includes(unimicroPlatformDomain);
}

export const ACCOUNTING_SYSTEM_TO_UNIMICRO_PLATFORM_DOMAIN_MAP: Record<AccountingSystem, UnimicroPlatformDomain> = {
  [AccountingSystem.UNIMICRO]: UnimicroPlatformDomain.UNIMICRO,
  [AccountingSystem.DNB_REGNSKAP]: UnimicroPlatformDomain.DNB_REGNSKAP,
  [AccountingSystem.SPARE_BANK_1_REGNSKAP]: UnimicroPlatformDomain.SPARE_BANK_1_REGNSKAP,
  [AccountingSystem.EIKA_REGNSKAP]: UnimicroPlatformDomain.EIKA_REGNSKAP,
  [AccountingSystem.TEST_UNIMICRO]: UnimicroPlatformDomain.TEST_UNIMICRO,
};
