import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Directive()
export class SubscriptionsManagerDirective implements OnDestroy {

  private readonly destroyer$$: Subject<void> = new Subject<void>();
  readonly destroyer$: Observable<void> = this.destroyer$$.asObservable();

  ngOnDestroy(): void {
    this.destroyer$$.next();
    this.destroyer$$.complete();
  }

}
