import {
  DEFAULT_INVOICE_REPORT_SORT_DIRECTION_TYPE,
  DEFAULT_INVOICE_REPORT_SORT_KEY_TYPE,
  INITIAL_INVOICES_AMOUNT,
  InvoiceReportListOptions,
} from '@WebUi/invoices/models/invoices.model';
import { InvoiceReport, InvoiceReportListResponse } from '@Libs/model';

export interface InvoicesStateModel {
  invoices: InvoiceReportListResponse | null;
  options: InvoiceReportListOptions;
  temporaryPendingInvoices: InvoiceReport[];
}

export const INVOICES_STATE_DEFAULTS: InvoicesStateModel = {
  invoices: null,
  options: {
    sortDirection: DEFAULT_INVOICE_REPORT_SORT_DIRECTION_TYPE,
    sortKey: DEFAULT_INVOICE_REPORT_SORT_KEY_TYPE,
    dateRangeOption: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    searchKey: '',
    status: [],
    salesChannels: [],
    offset: 0,
    pageSize: INITIAL_INVOICES_AMOUNT,
  },
  temporaryPendingInvoices: [],
};
