import { HttpBackend, HttpClient } from '@angular/common/http';
import { FALLBACK_APP_LANGUAGE } from '@Libs/model';
import { NonBlockingLoaderService } from '@WebUi/app/services/non-blocking-loader.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateDefaultParser, TranslateLoader, TranslateModuleConfig, TranslateParser } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { clone } from '@WebUi/helpers/clone';
import { RollbarService } from '@WebUi/app/services/rollbar.service';
import packageInfo from 'package.json';


export type TranslateLoaderOptions = {
  languagePlaceholder: string;
  files: TranslateFileMetadata[];
};

export type TranslateFileMetadata = {
  namespace: string;
  filePath: string;
};

export class TranslateHttpLoader implements TranslateLoader {

  private httpClient: HttpClient;

  private options: TranslateLoaderOptions = {
    languagePlaceholder: '{{lang}}',
    files: [
      { namespace: 'Components.App', filePath: `./assets/i18n/{{lang}}/modules/app.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Connections', filePath: `./assets/i18n/{{lang}}/modules/connections.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Dashboard', filePath: `./assets/i18n/{{lang}}/modules/dashboard.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Invoices', filePath: `./assets/i18n/{{lang}}/modules/invoices.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Notifications', filePath: `./assets/i18n/{{lang}}/modules/notifications.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Onboarding', filePath: `./assets/i18n/{{lang}}/modules/onboarding.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Orders', filePath: `./assets/i18n/{{lang}}/modules/orders.json?v=${packageInfo.version}`, },
      { namespace: 'Components.ProductSync', filePath: `./assets/i18n/{{lang}}/modules/product-sync.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Settings', filePath: `./assets/i18n/{{lang}}/modules/settings.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Settlements', filePath: `./assets/i18n/{{lang}}/modules/settlements.json?v=${packageInfo.version}`, },
      { namespace: 'Components.SetupWizard', filePath: `./assets/i18n/{{lang}}/modules/setup-wizard.json?v=${packageInfo.version}`, },
      { namespace: 'Components.Su', filePath: `./assets/i18n/{{lang}}/modules/su.json?v=${packageInfo.version}`, },
      { namespace: 'Forms', filePath: `./assets/i18n/{{lang}}/forms.json?v=${packageInfo.version}`, },
      { namespace: 'Modals', filePath: `./assets/i18n/{{lang}}/modals.json?v=${packageInfo.version}`, },
      { namespace: 'Shared', filePath: `./assets/i18n/{{lang}}/shared.json?v=${packageInfo.version}`, },
      { namespace: 'Toasts', filePath: `./assets/i18n/{{lang}}/toasts.json?v=${packageInfo.version}`, },
    ],
  };

  constructor(
    private httpBackend: HttpBackend,
    private nonBlockingLoaderService: NonBlockingLoaderService,
    private rollbarService: RollbarService,
  ) {
    this.httpClient = new HttpClient(this.httpBackend);
  }

  getTranslation(lang: string): Observable<unknown> {
    const requests: Record<string, Observable<unknown>> = this.options.files.reduce<Record<string, Observable<unknown>>>((accumulator: Record<string, Observable<unknown>>, metadata: TranslateFileMetadata) => {
      const filePath: string = `${metadata.filePath}`.replace(this.options.languagePlaceholder, lang);

      accumulator[metadata.namespace] = this.httpClient.get(filePath)
        .pipe(
          catchError((error) => {
            this.rollbarService.error('Translate file load error', error);

            return of({});
          }),
        );

      return accumulator;
    }, {});

    this.nonBlockingLoaderService.start();

    return forkJoin(requests)
      .pipe(
        // Merge all translate files
        map((data: Record<string, unknown>) => {
          const result: any = {};

          for (const [key, value] of Object.entries(data)) {
            const splitedNamespace: string[] = key.split('.');

            let resultPointer: any = result;

            for (let i = 0; i < splitedNamespace.length; ++i) {
              if (i === splitedNamespace.length - 1) {
                resultPointer[splitedNamespace[i]] = value;

                break;
              }

              if (resultPointer[splitedNamespace[i]] === undefined) {
                resultPointer[splitedNamespace[i]] = {};
              }

              resultPointer = resultPointer[splitedNamespace[i]];
            }

            resultPointer = value;
          }

          return result;
        }),
        finalize(() => {
          this.nonBlockingLoaderService.stop();
        }),
      );
  }

}

@Injectable()
export class ExtendedTranslateParser extends TranslateDefaultParser {

  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  override interpolate(expr: string | Object | Function, params?: unknown): any {
    if (typeof expr === 'string') {
      return this.interpolateStr(expr, params);
    } else if (typeof expr === 'function') {
      return this.interpolateFn(expr, params);
    } else if (typeof expr === 'object') {
      const cloneExpr = clone(expr);

      this.interpolateObj(cloneExpr, params);

      return cloneExpr;
    } else {
      return expr as string;
    }
  }

  private interpolateStr(expr: string, params?: unknown): string {
    if (!params) {
      return expr;
    }

    return expr.replace(this.templateMatcher, (substring: string, b: string) => {
      const r = this.getValue(params, b);

      return r !== null && r !== undefined ? r : substring;
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private interpolateFn(fn: Function, params?: unknown): string {
    return fn(params);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private interpolateObj(expr: any, params?: unknown): void {
    if (typeof expr !== 'object') {
      return;
    }

    for (const prop in expr) {
      if (typeof expr[prop] === 'object') {
        this.interpolateObj(expr[prop], params);
      } else {
        expr[prop] = this.interpolateStr(expr[prop], params);
      }
    }
  }

}

@Injectable()
export class ExtendedMissingTranslationHandler implements MissingTranslationHandler {

  private readonly handledKeys: Set<string> = new Set<string>();

  constructor(private rollbarService: RollbarService) { }

  handle(params: MissingTranslationHandlerParams): string {
    if (!this.handledKeys.has(params.key)) {
      this.rollbarService.error('Missing translation', params.key, params.interpolateParams);

      this.handledKeys.add(params.key);
    }

    return params.key;
  }

}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useClass: TranslateHttpLoader,
    deps: [
      HttpBackend,
      NonBlockingLoaderService,
      RollbarService,
    ],
  },
  parser: {
    provide: TranslateParser,
    useClass: ExtendedTranslateParser,
  },
  missingTranslationHandler: {
    provide: MissingTranslationHandler,
    useClass: ExtendedMissingTranslationHandler,
    deps: [
      RollbarService,
    ],
  },
  defaultLanguage: FALLBACK_APP_LANGUAGE,
};


export const TRANSLATE_CHILD_MODULE_CONFIG: TranslateModuleConfig = {
  ...TRANSLATE_MODULE_CONFIG,
  extend: true,
};
