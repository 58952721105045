import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CompanyConnection,
  ExternalSystemType,
  ID,
  UserCompany,
  isAccountingSystem,
  Agreement,
} from '@Libs/model';
import {
  ResetOnboarding,
  StoreOnboardingCompanyId,
  StoreOnboardingAgreement,
  StoreOnboardingShopifyToken,
  ResetOnboardingShopifyToken,
  StoreOnboardingUnimicroPlatformCompany,
  StoreOnboardingUnimicroPlatformCompanyKey,
  ResetOnboardingUnimicroPlatformCompanyKey,
  StoreOnboardingUnimicroPlatformCompanies,
  StoreOnboardingInput,
  PrepareOnboardingFlow,
  ResetOnboardingInput,
  ResetOnboardingFlow,
} from '@WebUi/onboarding/store/onboarding.actions';
import { OnboardingStateModel, ONBOARDING_STATE_DEFAULTS } from '@WebUi/onboarding/models/onboarding-state.model';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { ONBOARDING_SALES_CHANNEL_FLOW_STATE_DEFAULTS, ONBOARDING_PAYMENT_PROVIDER_FLOW_STATE_DEFAULTS, OnboardingFlowState, OnboardingInput, OnboardingOption } from '@WebUi/onboarding/models/onboarding.model';

@State<OnboardingStateModel>({
  name: 'onboarding',
  defaults: ONBOARDING_STATE_DEFAULTS,
})
@Injectable({
  providedIn: 'root',
})
export class OnboardingState {

  @Selector([OnboardingState])
  static input(state: OnboardingStateModel): OnboardingInput {
    return state.input;
  }

  @Selector([OnboardingState])
  static agreement(state: OnboardingStateModel): Agreement | null {
    return state.agreement;
  }

  @Selector([OnboardingState])
  static flowState(state: OnboardingStateModel): OnboardingFlowState | null {
    return state.flowState;
  }

  @Selector([OnboardingState])
  static companyId(state: OnboardingStateModel): ID | null {
    if (!state.flowState) {
      return null;
    }

    return state.flowState.companyId;
  }

  @Selector([DashboardState.userCompanies, OnboardingState.companyId])
  static company(userCompanies: UserCompany[], companyId: ID | null): UserCompany | null {
    if (companyId === null) {
      return null;
    }

    return userCompanies.find((company: UserCompany) => company.id === companyId) ?? null;
  }

  @Selector([
    OnboardingState.company,
    DashboardState.externalSystemTypes,
  ])
  static accountingSystemConnection(
    userCompany: UserCompany | null,
    externalSystemTypes: ExternalSystemType[] | null,
  ): CompanyConnection | null {
    if (!userCompany) {
      return null;
    }

    if (!externalSystemTypes) {
      return null;
    }

    return userCompany.connections.find((companyConnection: CompanyConnection) => isAccountingSystem(externalSystemTypes, companyConnection.externalSystemTypeId)) ?? null;
  }

  @Action(StoreOnboardingInput)
  storeOnboardingInput(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingInput): void {
    ctx.patchState({
      input: payload.input,
    });
  }

  @Action(StoreOnboardingAgreement)
  storeOnboardingAgreement(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingAgreement): void {
    ctx.patchState({
      agreement: payload.agreement,
    });
  }

  @Action(PrepareOnboardingFlow)
  prepareOnboardingFlow(ctx: StateContext<OnboardingStateModel>, payload: PrepareOnboardingFlow): void {
    switch (payload.flowStateRequiredData.option) {
      case OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE:
        ctx.patchState({
          flowState: {
            ...ONBOARDING_SALES_CHANNEL_FLOW_STATE_DEFAULTS,
            ...payload.flowStateRequiredData,
          },
        });

        break;
      case OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER:
        ctx.patchState({
          flowState: {
            ...ONBOARDING_PAYMENT_PROVIDER_FLOW_STATE_DEFAULTS,
            ...payload.flowStateRequiredData,
          },
        });

        break;
    }
  }

  @Action(ResetOnboardingFlow)
  resetOnboardingFlow(ctx: StateContext<OnboardingStateModel>): void {
    ctx.patchState({
      flowState: null,
    });
  }

  @Action(ResetOnboardingInput)
  resetOnboardingInput(ctx: StateContext<OnboardingStateModel>): void {
    ctx.patchState({
      input: {
        ...ONBOARDING_STATE_DEFAULTS.input,
      },
      agreement: null,
    });
  }

  @Action(StoreOnboardingCompanyId)
  storeOnboardingCompanyId(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingCompanyId): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    ctx.patchState({
      flowState: {
        ...state.flowState,
        companyId: payload.companyId,
      },
    });
  }

  @Action(StoreOnboardingUnimicroPlatformCompany)
  storeOnboardingUnimicroPlatformCompany(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingUnimicroPlatformCompany): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    ctx.patchState({
      flowState: {
        ...state.flowState,
        unimicroPlatformCompany: payload.unimicroPlatformCompany,
      },
    });
  }

  @Action(StoreOnboardingUnimicroPlatformCompanies)
  storeOnboardingUnimicroPlatformCompanies(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingUnimicroPlatformCompanies): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    ctx.patchState({
      flowState: {
        ...state.flowState,
        unimicroPlatformCompanies: payload.unimicroPlatformCompanies,
      },
    });
  }

  @Action(StoreOnboardingUnimicroPlatformCompanyKey)
  storeOnboardingUnimicroPlatformCompanyKey(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingUnimicroPlatformCompanyKey): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    ctx.patchState({
      flowState: {
        ...state.flowState,
        unimicroPlatformCompanyKey: payload.unimicroPlatformCompanyKey,
      },
    });
  }

  @Action(ResetOnboardingUnimicroPlatformCompanyKey)
  resetOnboardingUnimicroPlatformCompanyKey(ctx: StateContext<OnboardingStateModel>): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    ctx.patchState({
      flowState: {
        ...state.flowState,
        unimicroPlatformCompanyKey: null,
      },
    });
  }

  @Action(StoreOnboardingShopifyToken)
  storeOnboardingShopifyToken(ctx: StateContext<OnboardingStateModel>, payload: StoreOnboardingShopifyToken): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    switch (state.flowState.option) {
      case OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE:
        ctx.patchState({
          flowState: {
            ...state.flowState,
            shopifyToken: payload.shopifyToken,
          },
        });

        break;
    }
  }

  @Action(ResetOnboardingShopifyToken)
  resetOnboardingShopifyToken(ctx: StateContext<OnboardingStateModel>): void {
    const state: OnboardingStateModel = ctx.getState();

    if (!state.flowState) {
      return;
    }

    switch (state.flowState.option) {
      case OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE:
        ctx.patchState({
          flowState: {
            ...state.flowState,
            shopifyToken: null,
          },
        });

        break;
    }
  }

  @Action(ResetOnboarding)
  resetOnboarding(ctx: StateContext<OnboardingStateModel>): void {
    ctx.patchState({
      ...ONBOARDING_STATE_DEFAULTS,
    });
  }

}
