import { environment } from '@WebUi/env';
import { getActionTypeFromInstance, NgxsNextPluginFn, NgxsPlugin } from '@ngxs/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export class NgxsCustomLoggerPlugin implements NgxsPlugin {

  handle(state: any, action: any, next: NgxsNextPluginFn) {
    if (!environment.logging.ngxs) {
      return next(state, action);
    }

    console.group(`${getActionTypeFromInstance(action)} (${new Date().toLocaleString()})`);

    console.log(`${getActionTypeFromInstance(action)} (${new Date().toLocaleString()})`);

    if (this.hasPayload(action)) {
      console.log('Payload: ', { ...action });
    }

    return next(state, action)
      .pipe(
        tap(() => {
          console.log(`Complete: ${getActionTypeFromInstance(action)} (${new Date().toLocaleString()})`);

          console.groupEnd();
        }),
        catchError((error) => {
          console.log(`Error: ${getActionTypeFromInstance(action)} (${new Date().toLocaleString()})`);

          console.groupEnd();

          return throwError(() => error);
        }),
      );
  }

  private hasPayload(action: any): boolean {
    const nonEmptyProperties: unknown[] = this.getNonEmptyProperties(action);

    return nonEmptyProperties.length > 0;
  }

  private getNonEmptyProperties(action: any): unknown[] {
    const keys: string[] = Object.keys(action);
    const values: unknown[] = keys.map((key: string) => action[key]);

    return values.filter((value) => value !== undefined);
  }

}
