import { ApplicationRef, ComponentRef, enableProdMode, LOCALE_ID, NgModuleRef } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@WebUi/app/app.module';
import { AppComponent } from '@WebUi/app/pages/app/app.component';
import { environment } from '@WebUi/env';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [
      {
        provide: LOCALE_ID,
        useValue: 'nb-NO',
      },
    ],
  })
  .then((moduleRef: NgModuleRef<AppModule>) => {
    if (environment.production) {
      return;
    }

    const applicationRef: ApplicationRef = moduleRef.injector.get(ApplicationRef);
    const appComponent: ComponentRef<AppComponent> = applicationRef.components[0] as ComponentRef<AppComponent>;

    enableDebugTools(appComponent);
  })
  .catch((error) => console.error(error));
