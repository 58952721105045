import { gql } from 'apollo-angular';
import { User } from '../user';
import { UserCompanyFragment, UserFragment } from './fragments';

export const GET_USER = gql`
  query GetUser {
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export type GetUserResponse = {
  user: User;
}

export const GET_USER_COMPANIES = gql`
  query GetUserCompanies {
    user {
      companies {
        ...UserCompanyFragment
      }
    }
  }
  ${UserCompanyFragment}
`;

export type GetUserCompaniesResponse = {
  user: Pick<User, 'companies'>;
}
