import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { isApolloError } from '@apollo/client/errors';
import { RollbarService } from '@WebUi/app/services/rollbar.service';
import { environment } from '@WebUi/env';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(private rollbarService: RollbarService) { }

  handleError(error: Error): void {
    if (!environment.logging.rollbar) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    const payload: any = {};

    if (isApolloError(error)) {
      payload.debugData = {
        graphQLErrors: JSON.stringify(error.graphQLErrors, undefined, 2),
        clientErrors: JSON.stringify(error.clientErrors, undefined, 2),
        networkError:
          error.networkError instanceof HttpErrorResponse
            ? JSON.stringify({
              ...error.networkError,
              headers: error.networkError.headers ? this._httpHeadersToObject(error.networkError.headers as HttpHeaders) : {},
            }, undefined, 2)
            : JSON.stringify(error.networkError, undefined, 2),
        extraInfo: JSON.stringify(error.extraInfo, undefined, 2),
      };
    }

    this.rollbarService.error(error, payload);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  private _httpHeadersToObject(headers: HttpHeaders): any {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    const headersObject: any = {};

    for (const header of headers.keys()) {
      headersObject[header] = headers.get(header);
    }

    return headersObject;
  }

}
