import { SourceWarehouse, Warehouse } from './warehouses';

export enum DataTrackingEvent {
  CREATE = 0,
  UPDATE = 1,
  DELETE = 2,
};

export const DATA_TRACKING_EVENTS: DataTrackingEvent[] = [
  DataTrackingEvent.CREATE,
  DataTrackingEvent.UPDATE,
  DataTrackingEvent.DELETE,
];

export enum DataTrackingField {
  NAME = 0,
  VAT_CODE = 1,
  UNIT = 2,
  PRICE_EXCL_VAT = 3,
  GTIN = 4,
  IN_PRICE_EXCL_VAT = 5,
};

export const DATA_TRACKING_FIELDS: DataTrackingField[] = [
  DataTrackingField.NAME,
  DataTrackingField.VAT_CODE,
  DataTrackingField.UNIT,
  DataTrackingField.PRICE_EXCL_VAT,
  DataTrackingField.GTIN,
  DataTrackingField.IN_PRICE_EXCL_VAT,
];

export type ProductDistributionSettings = {
  enabled: boolean;
  receiveDataTrackingSettings: ReceiveDataTrackingSettings;
  sendDataTrackingSettings: SendDataTrackingSettings;
};

export type DataTrackingSettings = {
  dataTrackingEvents: DataTrackingEvent[];
  dataTrackingFields: DataTrackingField[];
};

export type ReceiveDataTrackingSettings = DataTrackingSettings & {
  inventoryWarehouseMappings: InventoryWarehouseMapping[];
  reboundDataFromExternalSystemId: string | null;
};

export type SendDataTrackingSettings = DataTrackingSettings;

export type InventoryWarehouseMapping = {
  destinationWarehouse: Warehouse;
  sourceWarehouses: SourceWarehouse[];
};

export const DEFAULT_PRODUCT_DISTRIBUTION_SETTINGS: ProductDistributionSettings = {
  enabled: false,
  receiveDataTrackingSettings: {
    dataTrackingEvents: [],
    dataTrackingFields: [],
    inventoryWarehouseMappings: [],
    reboundDataFromExternalSystemId: null,
  },
  sendDataTrackingSettings: {
    dataTrackingEvents: [],
    dataTrackingFields: [],
  },
};
