import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroiconsModule } from '@Libs/heroicons';
import { ToastsComponent } from './toasts/toasts.component';
import { ToastComponent } from './toast/toast.component';
import { DefaultToastPreparator, DEFAULT_TOAST_OPTIONS, TOAST_OPTIONS, TOAST_PREPARATOR } from './toasts';

@NgModule({
  declarations: [
    ToastsComponent,
    ToastComponent,
  ],
  imports: [
    CommonModule,
    HeroiconsModule,
  ],
  exports: [
    ToastsComponent,
  ],
  providers: [
    {
      provide: TOAST_OPTIONS,
      useValue: DEFAULT_TOAST_OPTIONS,
    },
    {
      provide: TOAST_PREPARATOR,
      useClass: DefaultToastPreparator,
      deps: [
        TOAST_OPTIONS,
      ],
    },
  ],
})
export class ToastsModule { }
