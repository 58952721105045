import { Directive, ElementRef, InjectionToken, Injector, OnChanges, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsService } from './svg-icons.service';

export interface SvgIconsModuleOptions {
  classNameFactory: (...args: string[]) => string;
  svgIconPathFactory: (...args: string[]) => string;
}

export const DEFAULT_SVG_ICONS_MODULE_OPTIONS: SvgIconsModuleOptions = {
  classNameFactory: (...args: string[]) => `svg-icon svg-icon_${args[0]}`,
  svgIconPathFactory: (...args: string[]) => `/assets/svg-icons/${args[0]}.svg`,
};

export const SVG_ICONS_MODULE_OPTIONS_TOKEN = new InjectionToken<SvgIconsModuleOptions>('SVG Icons module options');

@Directive()
export abstract class SvgIconAbstract implements OnInit, OnChanges {

  cssClasses!: string;

  private elementRef: ElementRef;
  private sanitizer: DomSanitizer;
  private svgIconsService: SvgIconsService;

  constructor(
    private injector: Injector,
  ) {
    this.elementRef = this.injector.get(ElementRef);
    this.sanitizer = this.injector.get(DomSanitizer);
    this.svgIconsService = this.injector.get(SvgIconsService);
  }

  ngOnInit(): void {
    this.cssClasses = this.className;

    this.setContent();
  }

  ngOnChanges(): void {
    this.cssClasses = this.className;

    this.setContent();
  }

  setContent(): void {
    this.svgIconsService.getIcon(this.svgIconPath)
      .subscribe((value: string | null) => {
        if (!value) {
          this.elementRef.nativeElement.innerHTML = '';

          return;
        }

        this.elementRef.nativeElement.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(value));
      });
  }

  abstract get className(): string;

  abstract get svgIconPath(): string;

}
