import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Heroicon, HeroiconStyle, HeroiconStyleType, HeroiconType } from '@Libs/heroicons';

@Component({
  selector: 'aeb-icon-input',
  templateUrl: './icon-input.component.html',
  styleUrls: ['./icon-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconInputComponent {

  @Input() icon!: Heroicon | HeroiconType;
  @Input() type!: HeroiconStyle | HeroiconStyleType;

}
