import { ExternalSystemTypeFeatureBillingable } from './external-system';

export type OnboardingContactForm = {
  feature: ExternalSystemTypeFeatureBillingable;
  productName: string;
  agreementId: number;
  agreementName: string;
  externalSystemTypes: string[];
  message: string;
};

export type CompanyUserInvitationResult = {
  invitation: CompanyUserInvitation;
  status: InvitationResultStatus.SUCCESSFULLY_INVITED_NEW_USER | InvitationResultStatus.EXISTING_INVITATION_PENDING;
} | {
  invitation: null;
  status: InvitationResultStatus.USER_ALREADY_HAS_ACCESS | InvitationResultStatus.ACCESS_GRANTED_TO_EXISTING_USER;
};

export enum InvitationResultStatus {
  SUCCESSFULLY_INVITED_NEW_USER = 0,
  USER_ALREADY_HAS_ACCESS = 1, // Invitation = null
  EXISTING_INVITATION_PENDING = 2,
  ACCESS_GRANTED_TO_EXISTING_USER = 3 // Invitation = null
};

export type CompanyUserInvitation = {
  id: number;
  email: string;
};

export function trackByCompanyUserInvitationId(index: number, companyUserInvitation: CompanyUserInvitation): number {
  return companyUserInvitation.id;
}
