import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
  aebLet: T;
  $implicit: T;
}

@Directive({
  selector: '[aebLet]',
  standalone: true,
})
export class LetDirective<T> {

  private context: LetContext<T | null> = {
    aebLet: null,
    $implicit: null,
  };

  private hasView = false;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<LetContext<T>>,
  ) { }

  @Input()
  set aebLet(value: T) {
    this.context.$implicit = this.context.aebLet = value;

    if (!this.hasView) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    }
  }

  static ngTemplateGuard_aebLet<T>(dir: LetDirective<T>, value: T): value is T {
    return true;
  }

  static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: any): ctx is LetContext<T> {
    return true;
  }

}
