import { Injectable } from '@angular/core';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { CompanyInfo, ID, PublicCompany, UserCompany } from '@Libs/model';
import { merge, Observable } from 'rxjs';
import { SuState } from '@WebUi/su/store/su.state';
import { map, take } from 'rxjs/operators';
import { CompaniesService } from '@WebUi/dashboard/services/companies.service';
import { DeleteObservedCompany, GetObservedCompany, SetActiveSuCompany } from '@WebUi/su/store/su.actions';
import {
  RevokeCompanyAccess,
  SetActiveCompany,
  UpdateCompany,
  UpdateCompanyConnection,
} from '@WebUi/dashboard/store/dashboard.actions';
import { UserActiveCompanyService } from '@WebUi/dashboard/services/user-active-company.service';

@Injectable({
  providedIn: 'root',
})
export class SuService {

  @Select(SuState.allCompanies) readonly allCompanies$!: Observable<CompanyInfo[]>;
  @Select(SuState.observedCompanies) readonly observedCompanies$!: Observable<UserCompany[]>;
  @Select(SuState.hasObservedCompanies) readonly hasObservedCompanies$!: Observable<boolean>;
  @Select(SuState.suCompanies) readonly suCompanies$!: Observable<UserCompany[]>;
  @Select(SuState.hasSuCompanies) readonly hasSuCompanies$!: Observable<boolean>;

  constructor(
    private companiesService: CompaniesService,
    private userActiveCompanyService: UserActiveCompanyService,
    private store: Store,
    private actions$: Actions,
  ) { }

  init(): void {
    // If current super user has changes in his own companies list
    // We need to check and remove repeated items from observed companies list
    this.companiesService.userCompanies$
      .subscribe((userCompanies: UserCompany[]) => {
        const userCompaniesIds: ID[] = userCompanies.map((company: UserCompany) => company.id);

        this.observedCompanies$
          .pipe(
            take(1),
          )
          .subscribe((observedCompanies: UserCompany[]) => {
            const observedCompaniesIds: ID[] = observedCompanies.map((company: UserCompany) => company.id);
            const observedCompaniesIdsToDelete: ID[] = userCompaniesIds.filter((companyId: ID) => observedCompaniesIds.includes(companyId));

            if (observedCompaniesIdsToDelete.length > 0) {
              this.store.dispatch(observedCompaniesIdsToDelete.map((companyId: ID) => new DeleteObservedCompany(companyId)));
            }
          });
      });

    merge(
      this.actions$
        .pipe(
          ofActionSuccessful(SetActiveCompany),
          map((action: SetActiveCompany) => action.companyId),
        ),
      this.userActiveCompanyService.activeCompanyId$,
    )
      .subscribe((activeUserCompanyId: ID | null) => {
        if (activeUserCompanyId) {
          this.store.dispatch([new SetActiveSuCompany(activeUserCompanyId)]);
        } else {
          this.observedCompanies$
            .pipe(
              take(1),
            )
            .subscribe((observedCompanies: UserCompany[]) => {
              if (observedCompanies[0]) {
                this.store.dispatch([new SetActiveSuCompany(observedCompanies[0].id)]);
              }
            });
        }
      });

    this.actions$
      .pipe(
        ofActionSuccessful(RevokeCompanyAccess, UpdateCompanyConnection, UpdateCompany),
      )
      .subscribe((action: RevokeCompanyAccess | UpdateCompanyConnection | UpdateCompany) => {
        const companyId: ID = 'companyId' in action ? action.companyId : action.company.id;

        this.observedCompanies$
          .pipe(
            take(1),
          )
          .subscribe((observedCompanies: UserCompany[]) => {
            const observedCompanyExists: boolean = observedCompanies.some((company: UserCompany) => company.id === companyId);

            if (observedCompanyExists) {
              this.store.dispatch([new GetObservedCompany(companyId)])
                .subscribe(() => {
                  this.store.dispatch([new SetActiveSuCompany(companyId)]);
                });
            }
          });
      });
  }

  isCompanyObserved$(companyId: string): Observable<boolean> {
    return this.observedCompanies$
      .pipe(
        map((observedCompanies: UserCompany[]) => observedCompanies.some((company: UserCompany) => company.id === companyId)),
        take(1),
      );
  }

  getUserCompanyByCompanyId$(companyId: ID): Observable<UserCompany | undefined> {
    return this.suCompanies$
      .pipe(
        map((suCompanies: UserCompany[]) => suCompanies.find(this.searchCompanyByCompanyIdPredicate(companyId))),
        take(1),
      );
  }

  private searchCompanyByCompanyIdPredicate(companyId: ID): (company: PublicCompany) => boolean {
    return (company: PublicCompany) => {
      return company.id === companyId;
    };
  }

}
