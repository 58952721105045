import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionsManagerDirective } from '@WebUi/shared/directives/subscriptions-manager.directive';

@Directive({
  selector: '[aebTooltip]',
})
export class TooltipDirective extends SubscriptionsManagerDirective implements OnInit, OnChanges, OnDestroy {

  // Be sure templateRef is available on 'OnInit' life cycle
  @Input() templateRef!: TemplateRef<unknown>;
  @Input() templateContext: unknown | undefined = undefined;
  @Input() mouseoverDelay = 500;
  @Input() mouseooutDelay = 125;
  @Input() withPositions: ConnectedPosition[] = [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 10,
      panelClass: 'center-top',
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -10,
      panelClass: 'center-bottom',
    },
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -10,
      panelClass: 'end-center',
    },
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 10,
      panelClass: 'start-center',
    },
  ];

  private readonly timerDestroyer$$: Subject<void> = new Subject();
  private readonly timerDestroyer$: Observable<void> = this.timerDestroyer$$.asObservable();

  private readonly disableTimerDestroyer$$: Subject<void> = new Subject();
  private readonly disableTimerDestroyer$: Observable<void> = this.disableTimerDestroyer$$.asObservable();

  private portal: TemplatePortal | undefined = undefined;
  private overlayRef: OverlayRef | undefined = undefined;

  constructor(
    private overlay: Overlay,
    private ref: ViewContainerRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo(this.ref.element)
        .withPositions(this.withPositions),
    });

    if (this.templateRef) {
      this.portal = new TemplatePortal(this.templateRef, this.ref, this.templateContext);
    }
  }

  ngOnChanges(): void {
    if (this.templateRef) {
      this.portal = new TemplatePortal(this.templateRef, this.ref, this.templateContext);
    }
  }

  @HostListener('mouseenter')
  onMouseOver(): void {
    this.disableTimerDestroyer$$.next();

    timer(this.mouseoverDelay)
      .pipe(
        takeUntil(this.timerDestroyer$),
        takeUntil(this.destroyer$),
      )
      .subscribe(() => {
        this.openOverlay();
      });
  }

  @HostListener('mouseleave')
  onMouseOut(): void {
    timer(this.mouseooutDelay)
      .pipe(
        takeUntil(this.disableTimerDestroyer$),
        takeUntil(this.destroyer$),
      )
      .subscribe(() => {
        this.timerDestroyer$$.next();

        this.closeOverlay();
      });
  }

  openOverlay(): void {
    if (!this.overlayRef) {
      return;
    }

    if (!this.portal) {
      return;
    }

    if (this.overlayRef.hasAttached()) {
      return;
    }

    this.overlayRef.attach(this.portal);
  }

  closeOverlay(): void {
    if (!this.overlayRef) {
      return;
    }

    if (!this.overlayRef.hasAttached()) {
      return;
    }

    this.overlayRef.detach();
  }

  override ngOnDestroy(): void {
    this.timerDestroyer$$.next();
    this.disableTimerDestroyer$$.next();

    this.closeOverlay();

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }

    this.timerDestroyer$$.complete();
    this.disableTimerDestroyer$$.complete();

    super.ngOnDestroy();
  }

}
