import { Agreement } from '@Libs/model';
import { OnboardingFlowState, OnboardingInput } from '@WebUi/onboarding/models/onboarding.model';

export type OnboardingStateModel = {
  input: OnboardingInput;
  agreement: Agreement | null;
  flowState: OnboardingFlowState | null;
};

export const ONBOARDING_STATE_DEFAULTS: OnboardingStateModel = {
  input: {
    option: null,
    feature: null,
    agreementId: null,
    accountingSystem: null,
    salesChannel: null,
    shopifyToken: null,
    hasEstore: null,
    paymentProvider: null,
  },
  agreement: null,
  flowState: null,
};
