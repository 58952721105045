import { environment } from '@WebUi/env';
import type Rollbar from 'rollbar';

export const DEFAULT_ROLLBAR_CONFIG: Rollbar.Configuration = {
  accessToken: 'bba781c608d24fdba3cf9c192e35d29e',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.name,
  },
};
