import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'aeb-toast-simple-content',
  templateUrl: './simple-content.component.html',
  styleUrls: ['./simple-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastSimpleContentComponent {

  @Input() content = '';

}
