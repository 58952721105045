export const environment = {
  name: 'dev',
  production: false,
  logging: {
    general: true,
    msal: true,
    ngxs: true,
    rollbar: true,
    routing: true
  },
  gateway: 'https://dev-api.bridge.unimicro.no',
  billingService: {
    private: 'https://dev-api.bridge.unimicro.no/private/billing',
    public: 'https://dev-api.bridge.unimicro.no/public/billing',
    su: 'https://dev-api.bridge.unimicro.no/su/billing',
  },
  signalRNotificationService: 'https://dev-api.bridge.unimicro.no/SignalRNotificationService',
  suSignalRNotificationService: 'https://dev-api.bridge.unimicro.no/su/SignalRNotificationService',
  companyManagerService: {
    private: 'https://dev-api.bridge.unimicro.no/private/company',
    public: 'https://dev-api.bridge.unimicro.no/public/company',
    anonymous: 'https://dev-api.bridge.unimicro.no/anonymous/company'
  },
  orderService: 'https://dev-api.bridge.unimicro.no/order',
  payoutService: 'https://dev-api.bridge.unimicro.no/payout',
  productSyncService: 'https://dev-api.bridge.unimicro.no/product',
  reportingService: 'https://dev-api.bridge.unimicro.no/reporting-service',
  unimicroPlatformService: 'https://dev-api.bridge.unimicro.no/softrig',
  vippsService: 'https://dev-api.bridge.unimicro.no/vipps',
  zettleService: 'https://dev-api.bridge.unimicro.no/zettle',
  installShopifyAppUrl: 'https://partners.shopify.com/1935997/apps/5820139/test',
  installMooCommerceAppUrl: 'https://moocommerce.no/unimicro-bridge',
  azure: {
    clientId: 'abe9186e-0ae1-4ca6-b702-431994bafdc0',
    authorityDomain: 'aecbaddev.b2clogin.com',
    userFlows: {
      signUpSignIn: {
        name: 'B2C_1A_SignUp_SignIn',
        authority: 'https://aecbaddev.b2clogin.com/aecbaddev.onmicrosoft.com/B2C_1A_SignUp_Signin',
      },
      signUpSignInOnboarding: {
        name: 'B2C_1A_SignUp_SignIn_Onboarding',
        authority: 'https://aecbaddev.b2clogin.com/aecbaddev.onmicrosoft.com/B2C_1A_SignUp_SignIn_Onboarding',
      },
      signUpSignInUniMicroAS: {
        name: 'B2C_1A_SignUp_SignIn_UniMicroAS',
        authority: 'https://aecbaddev.b2clogin.com/aecbaddev.onmicrosoft.com/B2C_1A_SignUp_SignIn_UniMicroAS',
      },
    },
  },
  accountingSystems: {
    unimicro: {
      systemUrl: 'https://app.unimicro.no',
    },
    dnbRegnskap: {
      systemUrl: 'https://dnbregnskap.dnb.no',
    },
    srBankRegnskap: {
      systemUrl: 'https://regnskap.sparebank1.no',
    },
    eikaRegnskap: {
      systemUrl: 'https://system.eikaregnskap.no',
    },
    testUnimicro: {
      systemUrl: 'https://test.unimicro.no',
    },
  },
};
