import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { APP_TITLE } from '@WebUi/helpers/injection-tokens';

@Component({
  selector: 'aeb-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {

  constructor(@Inject(APP_TITLE) public appTitle: string) { }

}
