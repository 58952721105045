import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Toast, ToastStatus } from '../toasts';
import { ToastsService } from '../toasts.service';

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() toast!: Toast;

  readonly ToastStatus = ToastStatus;

  @ViewChild(
    'content',
    {
      read: ViewContainerRef,
      static: false,
    },
  )
  contentViewContainerRef!: ViewContainerRef;

  @ViewChild(
    'autocloseProgress',
    {
      read: ElementRef,
      static: false,
    },
  )
  autocloseProgressRef!: ElementRef;

  private readonly destroyer$$: Subject<void> = new Subject<void>();
  readonly destroyer$: Observable<void> = this.destroyer$$.asObservable();

  private readonly hoverAutocloseDestroyer$$: Subject<void> = new Subject<void>();
  readonly hoverAutocloseDestroyer$: Observable<void> = this.hoverAutocloseDestroyer$$.asObservable();

  @HostBinding('class') cssClasses = 'toast';

  @HostBinding('class.toast_autoclose') get autoclose() {
    if (!this.toast) {
      return false;
    }

    return this.toast.autoclose;
  }

  @HostBinding('class.toast_with-close-btn') get withCloseBtn() {
    if (!this.toast) {
      return false;
    }

    return (this.toast.showCloseButton === 'auto' && !this.toast.autoclose) || (this.toast.showCloseButton === true);
  }

  animationFactory: AnimationFactory;

  autocloseProgressAnimationPlayer!: AnimationPlayer | null;

  constructor(
    private toastsService: ToastsService,
    private animationBuilder: AnimationBuilder,
  ) {
    this.animationFactory = this.animationBuilder.build([
      animate(
        '{{duration}}ms {{delay}}ms',
        style({ transform: 'translate3d({{translate}}, 0, 0)' }),
      ),
    ]);
  }

  ngOnInit(): void {
    if (this.toast.extraCssClass) {
      this.cssClasses = `toast toast_${this.toast.status} ${this.toast.extraCssClass}`;
    } else {
      this.cssClasses = `toast toast_${this.toast.status}`;
    }
  }

  ngAfterViewInit(): void {
    if (this.toast.contentFactory) {
      if (this.contentViewContainerRef) {
        const componentRef = this.contentViewContainerRef.createComponent(this.toast.contentFactory.component);

        if (this.toast.contentFactory.properties) {
          for (const property in this.toast.contentFactory.properties) {
            if (Object.prototype.hasOwnProperty.call(this.toast.contentFactory.properties, property)) {
              componentRef.setInput(property, this.toast.contentFactory.properties[property]);
            }
          }
        }

        componentRef.injector.get(ChangeDetectorRef).detectChanges();
      }
    }

    if (this.toast.autoclose) {
      if (this.toast.autocloseTimeout > 0) {
        if (this.autocloseProgressRef) {
          this.autocloseProgressAnimationPlayer = this.animationFactory.create(
            this.autocloseProgressRef.nativeElement,
            {
              params: {
                delay: 0,
                duration: this.toast.autocloseTimeout,
                translate: '-100%',
              },
            },
          );
        }

        this.initializeAutoclose();
      } else {
        this.toastsService.removeToast(this.toast.id);
      }
    }
  }

  initializeAutoclose(): void {
    if (this.autocloseProgressAnimationPlayer) {
      if (this.autocloseProgressAnimationPlayer.hasStarted()) {
        this.autocloseProgressAnimationPlayer.reset();
      }

      this.autocloseProgressAnimationPlayer.play();
    }

    timer(this.toast.autocloseTimeout)
      .pipe(
        takeUntil(this.hoverAutocloseDestroyer$),
        takeUntil(this.destroyer$),
      )
      .subscribe(() => {
        this.toastsService.removeToast(this.toast.id);
      });
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (!this.toast.autoclose) {
      return;
    }

    if (this.autocloseProgressAnimationPlayer) {
      this.autocloseProgressAnimationPlayer.pause();
    }

    this.hoverAutocloseDestroyer$$.next();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (!this.toast.autoclose) {
      return;
    }

    this.initializeAutoclose();
  }

  @HostListener('click')
  onToastClick(): void {
    if (!this.toast.autoclose) {
      return;
    }

    this.toastsService.removeToast(this.toast.id);
  }

  onCloseClick(): void {
    this.toastsService.removeToast(this.toast.id);
  }

  ngOnDestroy(): void {
    this.hoverAutocloseDestroyer$$.complete();

    this.destroyer$$.next();
    this.destroyer$$.complete();
  }

}
