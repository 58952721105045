import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { SubscriptionsManagerDirective } from '@WebUi/shared/directives/subscriptions-manager.directive';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { TranslatedElementDirective } from '@WebUi/shared/directives/translated-element.directive';

interface TranslationData {
  elements: TranslatedElementDirective[];
  rawTranslation: string;
}

const TOKEN_START_DEMARC = '{{';
const TOKEN_END_DEMARC = '}}';

// adapted from @kasperlauge's solution in https://github.com/ngx-translate/core/issues/223
@Directive({
  selector: '[aebTranslatedContent]',
})
export class TranslatedContentDirective extends SubscriptionsManagerDirective implements AfterContentInit {

  @Input('aebTranslatedContent') translationKey!: string;

  @ContentChildren(TranslatedElementDirective) private elements!: QueryList<TranslatedElementDirective>;

  constructor(
    private elementRef: ElementRef,
    private viewRef: ViewContainerRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterContentInit(): void {
    combineLatest([
      this.translateService.onLangChange.asObservable().pipe(startWith(() => of())),
      this.elements.changes.pipe(startWith(() => of())),
    ])
      .pipe(
        switchMap(() => this.translateService.get(this.translationKey)),
        map((rawTranslation: string) => {
          return {
            elements: this.elements.toArray(),
            rawTranslation,
          };
        }),
        takeUntil(this.destroyer$),
      )
      .subscribe(this.render.bind(this));
  }

  private render(translationData: TranslationData): void {
    if (!translationData.rawTranslation) {
      throw new Error(`No resource matching the key '${this.translationKey}'`);
    }

    // while (this.viewRef.element.nativeElement.firstChild) {
    //   this.renderer.removeChild(this.viewRef.element.nativeElement, this.viewRef.element.nativeElement.firstChild);
    // }
    this.elementRef.nativeElement.innerHTML = '';

    let lastTokenEnd = 0;

    while (lastTokenEnd < translationData.rawTranslation.length) {
      const tokenStartDemarc: number = translationData.rawTranslation.indexOf(TOKEN_START_DEMARC, lastTokenEnd);

      if (tokenStartDemarc < 0) {
        break;
      }

      const tokenStart: number = tokenStartDemarc + TOKEN_START_DEMARC.length;
      const tokenEnd: number = translationData.rawTranslation.indexOf(TOKEN_END_DEMARC, tokenStart);

      if (tokenEnd < 0) {
        throw new Error(`Encountered unterminated token in translation string '${this.translationKey}'`);
      }

      const tokenEndDemarc: number = tokenEnd + TOKEN_END_DEMARC.length;

      const precedingText: string = translationData.rawTranslation.substring(lastTokenEnd, tokenStartDemarc);
      const precedingTextElement = this.renderer.createText(precedingText);

      this.renderer.appendChild(this.viewRef.element.nativeElement, precedingTextElement);

      const elementKey: string = translationData.rawTranslation.substring(tokenStart, tokenEnd);
      const embeddedElementTemplate: TranslatedElementDirective | undefined = translationData.elements.find(element => element.elementKey === elementKey);

      if (embeddedElementTemplate) {
        const embeddedElementView = embeddedElementTemplate.viewRef.createEmbeddedView(embeddedElementTemplate.templateRef);

        this.renderer.appendChild(this.viewRef.element.nativeElement, embeddedElementView.rootNodes[0]);
      } else {
        const missingTokenText: string = translationData.rawTranslation.substring(tokenStartDemarc, tokenEndDemarc);
        const missingTokenElement = this.renderer.createText(missingTokenText);

        this.renderer.appendChild(this.viewRef.element.nativeElement, missingTokenElement);
      }

      lastTokenEnd = tokenEndDemarc;
    }

    const trailingText: string = translationData.rawTranslation.substring(lastTokenEnd);
    const trailingTextElement = this.renderer.createText(trailingText);

    this.renderer.appendChild(this.viewRef.element.nativeElement, trailingTextElement);

    // in case the rendering happens outside of a change detection mechanism
    // this ensures that any translations in the embedded elements are rendered
    this.cdr.detectChanges();
  }

}
