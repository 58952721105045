import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  CompanyConnection,
  CompanyConnectionState,
  ExternalSystemType,
  GetUserCompanyAsSuResponse,
  GetUserCompanyAsSuResponseVariables,
  GET_USER_COMPANY_AS_SU,
  ID,
  isAccountingSystemConnectionPredicate,
  UserCompany,
  GetAllCompaniesAsSuResponse,
  GET_ALL_COMPANIES_AS_SU,
  CompanyInfo,
  CompanyUser,
  CompanyUserRole,
} from '@Libs/model';
import { SuStateModel, SU_STATE_DEFAULTS } from '@WebUi/su/models/su-state.model';
import {
  GetObservedCompany,
  ResetObservedCompanies,
  AddObservedCompany,
  DeleteObservedCompany,
  UpdateObservedCompany,
  SetActiveSuCompany,
  StoreTvSettings,
  GetAllCompanies,
} from '@WebUi/su/store/su.actions';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { append, compose, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { TvSettings } from '@WebUi/su/models/su.model';
import { CompanyConnectionCreatedFeedback, SetCompanyOwner } from '@WebUi/dashboard/store/dashboard.actions';
import { clone } from '@WebUi/helpers/clone';

@State<SuStateModel>({
  name: 'su',
  defaults: SU_STATE_DEFAULTS,
})
@Injectable({
  providedIn: 'root',
})
export class SuState {

  @Selector([SuState])
  static allCompanies(state: SuStateModel): CompanyInfo[] {
    return state.allCompanies;
  }

  @Selector([SuState])
  static observedCompanies(state: SuStateModel): UserCompany[] {
    return state.observedCompanies;
  }

  @Selector([SuState.observedCompanies])
  static hasObservedCompanies(observedCompanies: UserCompany[]): boolean {
    return observedCompanies.length > 0;
  }

  @Selector([DashboardState.userCompanies, SuState.observedCompanies])
  static suCompanies(userCompanies: UserCompany[], observedCompanies: UserCompany[]): UserCompany[] {
    return userCompanies.concat(observedCompanies);
  }

  @Selector([SuState.suCompanies])
  static hasSuCompanies(suCompanies: UserCompany[]): boolean {
    return suCompanies.length > 0;
  }

  @Selector([SuState])
  static activeSuCompanyId(state: SuStateModel): ID | null {
    return state.activeSuCompanyId;
  }

  @Selector([SuState.suCompanies, SuState.activeSuCompanyId])
  static activeSuCompany(suCompanies: UserCompany[], activeSuCompanyId: ID | null): UserCompany | null {
    if (!activeSuCompanyId) {
      return null;
    }

    return suCompanies.find((company: UserCompany) => company.id === activeSuCompanyId) ?? null;
  }

  @Selector([SuState.activeSuCompany])
  static activeSuCompanyConnections(activeSuCompany: UserCompany | null): CompanyConnection[] | null {
    return activeSuCompany?.connections ?? null;
  }

  @Selector([SuState.activeSuCompanyConnections])
  static activeSuCompanyHasConnections(activeSuCompanyConnections: CompanyConnection[] | null): boolean | null {
    return activeSuCompanyConnections ? activeSuCompanyConnections.length > 0 : null;
  }

  @Selector([SuState.activeSuCompanyConnections])
  static activeSuCompanyNotBrokenConnections(activeSuCompanyConnections: CompanyConnection[] | null): CompanyConnection[] | null {
    return activeSuCompanyConnections?.filter((activeSuCompanyConnection: CompanyConnection) => activeSuCompanyConnection.state !== CompanyConnectionState.BROKEN) ?? null;
  }

  @Selector([SuState.activeSuCompanyNotBrokenConnections])
  static activeSuCompanyHasNotBrokenConnections(activeSuCompanyNotBrokenConnections: CompanyConnection[] | null): boolean | null {
    return activeSuCompanyNotBrokenConnections ? activeSuCompanyNotBrokenConnections.length > 0 : null;
  }

  @Selector([SuState.activeSuCompanyConnections])
  static activeSuCompanyBrokenConnections(activeSuCompanyConnections: CompanyConnection[] | null): CompanyConnection[] | null {
    return activeSuCompanyConnections?.filter((activeSuCompanyConnection: CompanyConnection) => activeSuCompanyConnection.state === CompanyConnectionState.BROKEN) ?? null;
  }

  @Selector([SuState.activeSuCompanyBrokenConnections])
  static activeSuCompanyHasBrokenConnections(activeSuCompanyBrokenConnections: CompanyConnection[] | null): boolean | null {
    return activeSuCompanyBrokenConnections ? activeSuCompanyBrokenConnections.length > 0 : null;
  }

  @Selector([
    SuState.activeSuCompanyConnections,
    DashboardState.externalSystemTypes,
  ])
  static activeSuCompanyAccountingSystemConnection(
    connections: CompanyConnection[],
    externalSystemTypes: ExternalSystemType[] | null,
  ): CompanyConnection | null {
    if (!externalSystemTypes) {
      return null;
    }

    return connections.find(isAccountingSystemConnectionPredicate(externalSystemTypes)) ?? null;
  }

  @Selector([SuState])
  static tvSettings(state: SuStateModel): TvSettings {
    return state.tvSettings;
  }

  constructor(private apollo: Apollo) { }

  @Action(SetActiveSuCompany)
  setActiveSuCompany(ctx: StateContext<SuStateModel>, payload: SetActiveSuCompany): void {
    ctx.patchState({
      activeSuCompanyId: payload.companyId,
    });
  }

  @Action(SetCompanyOwner)
  setCompanyOwner(ctx: StateContext<SuStateModel>, payload: SetCompanyOwner): void {
    ctx.setState(
      patch({
        observedCompanies: updateItem<UserCompany>((company: Readonly<UserCompany>) => company.id === payload.companyUser.companyId, patch({
          users: compose(
            updateItem<CompanyUser>((user: Readonly<CompanyUser>) => user.role === CompanyUserRole.OWNER, patch({
              role: CompanyUserRole.REGULAR,
            })),
            updateItem<CompanyUser>((user: Readonly<CompanyUser>) => user.userId === payload.companyUser.userId, patch({
              role: CompanyUserRole.OWNER,
            })),
          ),
        })),
      }),
    );
  }

  @Action(GetAllCompanies)
  getAllCompanies(ctx: StateContext<SuStateModel>): Observable<ApolloQueryResult<GetAllCompaniesAsSuResponse>> {
    return this.apollo.use('companyManager').query<GetAllCompaniesAsSuResponse>({
      query: GET_ALL_COMPANIES_AS_SU,
    })
      .pipe(
        filter((result: ApolloQueryResult<GetAllCompaniesAsSuResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        tap((result: ApolloQueryResult<GetAllCompaniesAsSuResponse>) => {
          ctx.patchState({
            allCompanies: result.data.companies,
          });
        }),
      );
  }

  @Action(GetObservedCompany)
  getObservedCompany(ctx: StateContext<SuStateModel>, payload: GetObservedCompany): Observable<void> {
    return this.apollo.use('companyManager').query<GetUserCompanyAsSuResponse, GetUserCompanyAsSuResponseVariables>({
      query: GET_USER_COMPANY_AS_SU,
      variables: {
        companyId: payload.companyId,
      },
    })
      .pipe(
        filter((result: ApolloQueryResult<GetUserCompanyAsSuResponse>) => !!result && !result.loading && !result.partial && !result.error && !result.errors && !!result.data),
        switchMap((result: ApolloQueryResult<GetUserCompanyAsSuResponse>) => {
          const observedCompanyExists: boolean = ctx.getState().observedCompanies.some((company: UserCompany) => company.id === result.data.superUser.company.id);

          if (observedCompanyExists) {
            return ctx.dispatch([new UpdateObservedCompany(result.data.superUser.company)]);
          } else {
            return ctx.dispatch([new AddObservedCompany(result.data.superUser.company)]);
          }
        }),
      );
  }

  @Action(AddObservedCompany)
  addObservedCompany(ctx: StateContext<SuStateModel>, payload: AddObservedCompany): void {
    ctx.setState(
      patch({
        observedCompanies: append<UserCompany>([payload.observedCompany]),
      }),
    );
  }

  @Action(UpdateObservedCompany)
  updateObservedCompany(ctx: StateContext<SuStateModel>, payload: UpdateObservedCompany): void {
    ctx.setState(
      patch({
        observedCompanies: updateItem<UserCompany>(
          (company?: Readonly<UserCompany>) => company?.id === payload.observedCompany.id,
          payload.observedCompany,
        ),
      }),
    );
  }

  @Action(DeleteObservedCompany)
  deleteObservedCompany(ctx: StateContext<SuStateModel>, payload: DeleteObservedCompany): void {
    ctx.setState(
      patch({
        observedCompanies: removeItem<UserCompany>((company?: Readonly<UserCompany>) => company?.id === payload.companyId),
      }),
    );
  }

  @Action(ResetObservedCompanies)
  resetObservedCompanies(ctx: StateContext<SuStateModel>): void {
    ctx.patchState({
      observedCompanies: [],
    });
  }

  @Action(StoreTvSettings)
  storeTvSettings(ctx: StateContext<SuStateModel>, payload: StoreTvSettings): void {
    ctx.patchState({
      tvSettings: payload.tvSettings,
    });
  }

  /**
   * React on feedback actions
   * Super user doesnt get notification by signalr so this is the only way to actualize SU state
  */
  @Action(CompanyConnectionCreatedFeedback)
  companyConnectionCreatedFeedback(ctx: StateContext<SuStateModel>, payload: CompanyConnectionCreatedFeedback): void {
    const observedCompanies: UserCompany[] = clone(ctx.getState().observedCompanies);

    const observedCompanyIndex: number = observedCompanies.findIndex((company: UserCompany) => company.id === payload.companyId);

    if (observedCompanyIndex === -1) {
      // There is no observed company with needed companyId

      return;
    }

    const connectionIndex: number = observedCompanies[observedCompanyIndex].connections.findIndex((connection: CompanyConnection) => connection.id === payload.companyConnection.id);

    if (connectionIndex > -1) {
      // For some reason connectionId presents in company connections list already

      return;
    }

    observedCompanies[observedCompanyIndex].connections.push(payload.companyConnection);

    ctx.setState(
      patch({
        observedCompanies: observedCompanies,
      }),
    );
  }

}
