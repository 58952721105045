<div class="prompt-modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <ng-container *ngIf="heading">
        <div class="modal-title">{{ heading }}</div>
      </ng-container>

      <button type="button" class="btn close-btn" [attr.title]="'Shared.Buttons.Close' | translate"
        (click)="onCloseClick()">
        <heroicon icon="x" type="outline"></heroicon>
      </button>
    </div>

    <ng-container *ngIf="content">
      <div class="modal-body">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="actions">
      <div class="modal-footer">
        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </div>
    </ng-container>
  </div>
</div>
