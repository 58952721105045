import { InjectionToken } from '@angular/core';
import { SvgIconsModuleOptions } from '@Libs/svg-icons';

export interface HeroiconsModuleOptions extends SvgIconsModuleOptions {
  defaultStyle: HeroiconStyle;
}
export enum HeroiconStyle {
  Solid = 'solid',
  Outline = 'outline',
}

export enum Heroicon {
  AcademicCap = 'academic-cap',
  Adjustments = 'adjustments',
  Annotation = 'annotation',
  Archive = 'archive',
  ArrowCircleDown = 'arrow-circle-down',
  ArrowCircleLeft = 'arrow-circle-left',
  ArrowCircleRight = 'arrow-circle-right',
  ArrowCircleUp = 'arrow-circle-up',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowNarrowDown = 'arrow-narrow-down',
  ArrowNarrowLeft = 'arrow-narrow-left',
  ArrowNarrowRight = 'arrow-narrow-right',
  ArrowNarrowUp = 'arrow-narrow-up',
  ArrowRight = 'arrow-right',
  ArrowSmDown = 'arrow-sm-down',
  ArrowSmLeft = 'arrow-sm-left',
  ArrowSmRight = 'arrow-sm-right',
  ArrowSmUp = 'arrow-sm-up',
  ArrowUp = 'arrow-up',
  ArrowsExpand = 'arrows-expand',
  AtSymbol = 'at-symbol',
  Backspace = 'backspace',
  BadgeCheck = 'badge-check',
  Ban = 'ban',
  Beaker = 'beaker',
  Bell = 'bell',
  BookOpen = 'book-open',
  BookmarkAlt = 'bookmark-alt',
  Bookmark = 'bookmark',
  Briefcase = 'briefcase',
  Cake = 'cake',
  Calculator = 'calculator',
  Calendar = 'calendar',
  Camera = 'camera',
  Cash = 'cash',
  ChartBar = 'chart-bar',
  ChartPie = 'chart-pie',
  ChartSquareBar = 'chart-square-bar',
  ChatAlt2 = 'chat-alt-2',
  ChatAlt = 'chat-alt',
  Chat = 'chat',
  CheckCircle = 'check-circle',
  Check = 'check',
  ChevronDoubleDown = 'chevron-double-down',
  ChevronDoubleLeft = 'chevron-double-left',
  ChevronDoubleRight = 'chevron-double-right',
  ChevronDoubleUp = 'chevron-double-up',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  Chip = 'chip',
  ClipboardCheck = 'clipboard-check',
  ClipboardCopy = 'clipboard-copy',
  ClipboardList = 'clipboard-list',
  Clipboard = 'clipboard',
  Clock = 'clock',
  CloudDownload = 'cloud-download',
  CloudUpload = 'cloud-upload',
  Cloud = 'cloud',
  Code = 'code',
  Cog = 'cog',
  Collection = 'collection',
  ColorSwatch = 'color-swatch',
  CreditCard = 'credit-card',
  CubeTransparent = 'cube-transparent',
  Cube = 'cube',
  CurrencyBangladeshi = 'currency-bangladeshi',
  CurrencyDollar = 'currency-dollar',
  CurrencyEuro = 'currency-euro',
  CurrencyPound = 'currency-pound',
  CurrencyRupee = 'currency-rupee',
  CurrencyYen = 'currency-yen',
  CursorClick = 'cursor-click',
  Database = 'database',
  DesktopComputer = 'desktop-computer',
  DeviceMobile = 'device-mobile',
  DeviceTablet = 'device-tablet',
  DocumentAdd = 'document-add',
  DocumentDownload = 'document-download',
  DocumentDuplicate = 'document-duplicate',
  DocumentRemove = 'document-remove',
  DocumentReport = 'document-report',
  DocumentSearch = 'document-search',
  DocumentText = 'document-text',
  Document = 'document',
  DotsCircleHorizontal = 'dots-circle-horizontal',
  DotsHorizontal = 'dots-horizontal',
  DotsVertical = 'dots-vertical',
  Download = 'download',
  Duplicate = 'duplicate',
  EmojiHappy = 'emoji-happy',
  EmojiSad = 'emoji-sad',
  ExclamationCircle = 'exclamation-circle',
  Exclamation = 'exclamation',
  ExternalLink = 'external-link',
  EyeOff = 'eye-off',
  Eye = 'eye',
  FastForward = 'fast-forward',
  Film = 'film',
  Filter = 'filter',
  FingerPrint = 'finger-print',
  Fire = 'fire',
  Flag = 'flag',
  FolderAdd = 'folder-add',
  FolderDownload = 'folder-download',
  FolderOpen = 'folder-open',
  FolderRemove = 'folder-remove',
  Folder = 'folder',
  Gift = 'gift',
  GlobeAlt = 'globe-alt',
  Globe = 'globe',
  Hand = 'hand',
  Hashtag = 'hashtag',
  Heart = 'heart',
  Home = 'home',
  Identification = 'identification',
  InboxIn = 'inbox-in',
  Inbox = 'inbox',
  InformationCircle = 'information-circle',
  Key = 'key',
  Library = 'library',
  LightBulb = 'light-bulb',
  LightningBolt = 'lightning-bolt',
  Link = 'link',
  LocationMarker = 'location-marker',
  LockClosed = 'lock-closed',
  LockOpen = 'lock-open',
  Login = 'login',
  Logout = 'logout',
  MailOpen = 'mail-open',
  Mail = 'mail',
  Map = 'map',
  MenuAlt1 = 'menu-alt-1',
  MenuAlt2 = 'menu-alt-2',
  MenuAlt3 = 'menu-alt-3',
  MenuAlt4 = 'menu-alt-4',
  Menu = 'menu',
  Microphone = 'microphone',
  MinusCircle = 'minus-circle',
  MinusSm = 'minus-sm',
  Minus = 'minus',
  Moon = 'moon',
  MusicNote = 'music-note',
  Newspaper = 'newspaper',
  OfficeBuilding = 'office-building',
  PaperAirplane = 'paper-airplane',
  PaperClip = 'paper-clip',
  Pause = 'pause',
  PencilAlt = 'pencil-alt',
  Pencil = 'pencil',
  PhoneIncoming = 'phone-incoming',
  PhoneMissedCall = 'phone-missed-call',
  PhoneOutgoing = 'phone-outgoing',
  Phone = 'phone',
  Photograph = 'photograph',
  Play = 'play',
  PlusCircle = 'plus-circle',
  PlusSm = 'plus-sm',
  Plus = 'plus',
  PresentationChartBar = 'presentation-chart-bar',
  PresentationChartLine = 'presentation-chart-line',
  Printer = 'printer',
  Puzzle = 'puzzle',
  Qrcode = 'qrcode',
  QuestionMarkCircle = 'question-mark-circle',
  ReceiptRefund = 'receipt-refund',
  ReceiptTax = 'receipt-tax',
  Refresh = 'refresh',
  Reply = 'reply',
  Rewind = 'rewind',
  Rss = 'rss',
  SaveAs = 'save-as',
  Save = 'save',
  Scale = 'scale',
  Scissors = 'scissors',
  SearchCircle = 'search-circle',
  Search = 'search',
  Selector = 'selector',
  Server = 'server',
  Share = 'share',
  ShieldCheck = 'shield-check',
  ShieldExclamation = 'shield-exclamation',
  ShoppingBag = 'shopping-bag',
  ShoppingCart = 'shopping-cart',
  SortAscending = 'sort-ascending',
  SortDescending = 'sort-descending',
  Sparkles = 'sparkles',
  Speakerphone = 'speakerphone',
  Star = 'star',
  StatusOffline = 'status-offline',
  StatusOnline = 'status-online',
  Stop = 'stop',
  Sun = 'sun',
  Support = 'support',
  SwitchHorizontal = 'switch-horizontal',
  SwitchVertical = 'switch-vertical',
  Table = 'table',
  Tag = 'tag',
  Template = 'template',
  Terminal = 'terminal',
  ThumbDown = 'thumb-down',
  ThumbUp = 'thumb-up',
  Ticket = 'ticket',
  Translate = 'translate',
  Trash = 'trash',
  TrendingDown = 'trending-down',
  TrendingUp = 'trending-up',
  Truck = 'truck',
  Upload = 'upload',
  UserAdd = 'user-add',
  UserCircle = 'user-circle',
  UserGroup = 'user-group',
  UserRemove = 'user-remove',
  User = 'user',
  Users = 'users',
  Variable = 'variable',
  VideoCamera = 'video-camera',
  ViewBoards = 'view-boards',
  ViewGridAdd = 'view-grid-add',
  ViewGrid = 'view-grid',
  ViewList = 'view-list',
  VolumeOff = 'volume-off',
  VolumeUp = 'volume-up',
  Wifi = 'wifi',
  XCircle = 'x-circle',
  X = 'x',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
}

export type HeroiconType = `${Heroicon}`;

export type HeroiconStyleType = `${HeroiconStyle}`;

export const HEROICONS_MODULE_OPTIONS_TOKEN = new InjectionToken<HeroiconsModuleOptions>('Heroicons module options');

export const DEFAULT_HEROICONS_MODULE_OPTIONS: HeroiconsModuleOptions = {
  classNameFactory: (...args: string[]) => `heroicon heroicon_${args[0]} heroicon_${args[1]}`,
  svgIconPathFactory: (...args: string[]) => `/assets/heroicons/${args[0]}/${args[1]}.svg`,
  defaultStyle: HeroiconStyle.Outline,
};
