export enum FlowStepState {
  UNTOUCHED = 0,
  ACTIVE = 1,
  COMPLETED = 2,
  SKIPPED = 3,
}

export type FlowStepHorizontal = {
  state: FlowStepState;
  label?: string;
};

export type FlowStepVertical = {
  state: FlowStepState;
  label: string;
  description: string;
};
