import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class IndexGuard implements CanActivate {

  constructor(
    private msalService: MsalService,
    private router: Router,
  ) { }

  canActivate(): boolean | UrlTree {
    if (this.msalService.instance.getActiveAccount()) {
      return this.router.parseUrl('/dashboard');
    }

    return this.router.parseUrl('/login');
  }

}
