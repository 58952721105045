import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { DEFAULT_ANIMATION_OPTIONS } from '@WebUi/helpers/animation';
import { collapseOnLeaveAnimation, expandOnEnterAnimation, rotateAnimation } from 'angular-animations';

@Component({
  selector: 'aeb-toast-expandable-content',
  templateUrl: './expandable-content.component.html',
  styleUrls: ['./expandable-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    expandOnEnterAnimation(DEFAULT_ANIMATION_OPTIONS),
    collapseOnLeaveAnimation(DEFAULT_ANIMATION_OPTIONS),
    rotateAnimation({
      ...DEFAULT_ANIMATION_OPTIONS,
      degrees: 180,
    }),
  ],
})
export class ToastExpandableContentComponent implements OnInit {

  @Input() content = '';
  @Input() expandableContent = '';

  maxWidth = 0;

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.maxWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
  }

}
