import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConnectionsStateModel, CONNECTIONS_STATE_DEFAULTS } from '@WebUi/connections/models/connections-state.model';
import {
  ResetExternalSystemToConnect,
  ResetExternalSystemToConnectIntention,
  StoreExternalSystemToConnect,
  StoreExternalSystemToConnectIntention,
} from '@WebUi/connections/store/connections.actions';
import { ExternalSystemToConnect, ExternalSystemToConnectIntention } from '@WebUi/connections/models/connections.model';

@State<ConnectionsStateModel>({
  name: 'connections',
  defaults: CONNECTIONS_STATE_DEFAULTS,
})
@Injectable({
  providedIn: 'root',
})
export class ConnectionsState {

  @Selector([ConnectionsState])
  static externalSystemToConnect(state: ConnectionsStateModel): ExternalSystemToConnect | null {
    return state.externalSystemToConnect;
  }

  @Selector([ConnectionsState])
  static externalSystemToConnectIntention(state: ConnectionsStateModel): ExternalSystemToConnectIntention | null {
    return state.externalSystemToConnectIntention;
  }

  @Action(StoreExternalSystemToConnect)
  storeExternalSystemToConnect(ctx: StateContext<ConnectionsStateModel>, payload: StoreExternalSystemToConnect): void {
    ctx.patchState({
      externalSystemToConnect: payload.externalSystem,
    });
  }

  @Action(ResetExternalSystemToConnect)
  resetExternalSystemToConnect(ctx: StateContext<ConnectionsStateModel>): void {
    ctx.patchState({
      externalSystemToConnect: null,
    });
  }

  @Action(StoreExternalSystemToConnectIntention)
  storeExternalSystemToConnectIntention(ctx: StateContext<ConnectionsStateModel>, payload: StoreExternalSystemToConnectIntention): void {
    ctx.patchState({
      externalSystemToConnectIntention: payload.externalSystem,
    });
  }

  @Action(ResetExternalSystemToConnectIntention)
  resetExternalSystemToConnectIntention(ctx: StateContext<ConnectionsStateModel>): void {
    ctx.patchState({
      externalSystemToConnectIntention: null,
    });
  }

}
