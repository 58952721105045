import { insecureRandomUUID } from '@WebUi/helpers/polyfills/random-uuid';

export function uuid(): string {
  if (crypto?.randomUUID) {
    return crypto.randomUUID();
  }

  return insecureRandomUUID();
}

