import { InjectionToken, Type } from '@angular/core';

export enum ToastStatus {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export const TOAST_STATUSES: ToastStatus[] = [
  ToastStatus.INFO,
  ToastStatus.SUCCESS,
  ToastStatus.WARNING,
  ToastStatus.ERROR,
];

export interface Toast {
  id: string;
  heading: string;
  contentFactory?: {
    component: Type<unknown>,
    properties?: {
      [k in string]: unknown;
    },
  };
  status: ToastStatus;
  autoclose: boolean;
  autocloseTimeout: number;
  showCloseButton: boolean | 'auto';
  extraCssClass?: string;
}

/*
 * List of options user can predefine
 */
export type ToastOptions = Partial<Pick<Toast, 'status' | 'autoclose' | 'autocloseTimeout' | 'showCloseButton' | 'extraCssClass'>>;

export const DEFAULT_TOAST_OPTIONS: ToastOptions = {
  status: ToastStatus.INFO,
  autoclose: false,
  autocloseTimeout: 5000,
  showCloseButton: 'auto',
}

export const TOAST_OPTIONS = new InjectionToken<ToastOptions>('Default toast options');

export type ToastSample = Pick<Toast, 'heading' | 'contentFactory'> & ToastOptions;

/*
 * Converts ToastSample object into real Toast object with toastId
 */
export interface ToastPreparator {
  prepare: (toastSample: ToastSample) => Toast;
}

export class DefaultToastPreparator implements ToastPreparator {

  static counter = 0;

  constructor(private toastsOptions: ToastOptions) { }

  prepare(toastSample: ToastSample) {
    const normalizedToastsOptions: ToastOptions = {
      ...DEFAULT_TOAST_OPTIONS,
      ...this.toastsOptions,
    };

    const toast: Toast = {
      id: `${Date.now()}_${DefaultToastPreparator.counter}`,
      heading: toastSample.heading,
      contentFactory: toastSample.contentFactory,
      status: toastSample.status ?? normalizedToastsOptions.status,
      showCloseButton: toastSample.showCloseButton ?? normalizedToastsOptions.showCloseButton,
      autoclose: toastSample.autoclose ?? normalizedToastsOptions.autoclose,
      autocloseTimeout: toastSample.autocloseTimeout ?? normalizedToastsOptions.autocloseTimeout,
      extraCssClass: toastSample.extraCssClass ?? normalizedToastsOptions.extraCssClass,
    } as Toast;

    DefaultToastPreparator.counter++;

    return toast;
  }

}

export const TOAST_PREPARATOR = new InjectionToken<ToastPreparator>('Converter ToastSample -> Toast');
