import { APP_INITIALIZER, ErrorHandler, inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@WebUi/app/app.routing.module';
import { AppComponent } from '@WebUi/app/pages/app/app.component';
import { NgxsModule, NGXS_PLUGINS, Store } from '@ngxs/store';
import { NGXS_MODULE_CONFIG } from '@WebUi/app/configs/ngxs-module.config';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsCustomLoggerPlugin } from '@WebUi/app/plugins/ngxs-custom-logger.plugin';
import { GraphQLModule } from '@WebUi/graphql/graphql.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RetryInterceptor } from '@WebUi/app/interceptors/retry.interceptor';
import { StartupComponent } from '@WebUi/app/pages/startup/startup.component';
import { SuStartupComponent } from '@WebUi/app/pages/su-startup/su-startup.component';
import { DevStartupComponent } from '@WebUi/app/pages/dev-startup/dev-startup.component';
import { SharedModule } from '@WebUi/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpServerErrorInterceptor } from '@WebUi/app/interceptors/http-server-error.interceptor';
import { clearStatePlugin } from '@WebUi/app/plugins/clear-state.plugin';
import { AppState } from '@WebUi/app/store/app.state';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TRANSLATE_MODULE_CONFIG } from '@WebUi/app/configs/translate-module.config';
import { DOCUMENT, Location } from '@angular/common';
import {
  langInitializerFactory,
  prefersReducedMotion,
  servicesInitializerFactory,
  stateActualizerBeforeInitFactory,
} from '@WebUi/app/configs/app-module.config';
import { ShopifyConnectionTokenHandlerComponent } from '@WebUi/app/pages/shopify-connection-token-handler/shopify-connection-token-handler.component';
import { ActionsWatcherService } from '@WebUi/app/services/actions-watcher.service';
import { LayoutComponent } from '@WebUi/app/layouts/layout/layout.component';
import { LayoutLoginComponent } from '@WebUi/app/layouts/layout-login/layout-login.component';
import { OnboardingState } from '@WebUi/onboarding/store/onboarding.state';
import { RequestIdInterceptor } from '@WebUi/app/interceptors/request-id.interceptor';
import { XEBridgeCompanyIdInterceptor } from '@WebUi/app/interceptors/x-ebridge-companyid.interceptor';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { NotFoundComponent } from '@WebUi/app/pages/not-found/not-found.component';
import { ZettleConnectionHandlerComponent } from '@WebUi/app/pages/zettle-connection-handler/zettle-connection-handler.component';
import { ConnectionsState } from '@WebUi/connections/store/connections.state';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HeroiconsModule } from '@Libs/heroicons';
import { ToastsComponent, ToastsModule } from '@Libs/toasts';
import { environment } from '@WebUi/env';
import { ActiveCompanyService } from '@WebUi/dashboard/services/active-company.service';
import { AuthService } from '@WebUi/app/services/auth.service';
import { UserActiveCompanyService } from '@WebUi/dashboard/services/user-active-company.service';
import { SuActiveCompanyService } from '@WebUi/su/services/su-active-company.service';
import { Router } from '@angular/router';
import {
  msalInitializerFactory,
  MSALInstanceFactory,
  MSALGuardConfigFactory,
  MSALInterceptorConfigFactory,
} from '@WebUi/app/configs/auth-module.config';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalService,
} from '@azure/msal-angular';
import { OnboardingService } from '@WebUi/onboarding/services/onboarding.service';
import { AuthorizationInterceptor } from '@WebUi/app/interceptors/authorization.interceptor';
import { IndexComponent } from '@WebUi/app/pages/index/index.component';
import { SetupWizardState } from '@WebUi/setup-wizard/store/setup-wizard.state';
import { LoggingOutBlockingLoaderService } from '@WebUi/app/services/logging-out-blocking-loader.service';
import { SilentAuthComponent } from '@WebUi/app/pages/silent-auth/silent-auth.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { AebDateAdapter } from '@WebUi/app/services/date-adapter.service';
import { AnimatedDialogConfig, DEFAULT_ANIMATED_DIALOG_CONFIG } from '@Libs/animated-dialog';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from 'libs/animated-dialog/src/lib/animated-dialog-animations';
import { DEFAULT_ANIMATION_DURATION } from '@WebUi/helpers/animation';
import { PermissionsService } from '@WebUi/dashboard/services/permissions.service';
import { RollbarService } from '@WebUi/app/services/rollbar.service';
import { RollbarErrorHandler } from '@WebUi/app/services/rollbar-error-handler.service';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    StartupComponent,
    SuStartupComponent,
    DevStartupComponent,
    SilentAuthComponent,
    NotFoundComponent,
    ShopifyConnectionTokenHandlerComponent,
    ZettleConnectionHandlerComponent,
    LayoutComponent,
    LayoutLoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: prefersReducedMotion(),
    }),
    ReactiveFormsModule,
    HttpClientModule,
    GraphQLModule,
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
    ToastsModule,
    NgxsModule.forRoot(
      [
        AppState,
        DashboardState,
        ConnectionsState,
        OnboardingState,
        SetupWizardState,
      ],
      NGXS_MODULE_CONFIG,
    ),
    HeroiconsModule,
    NgxsStoragePluginModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    MatNativeDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.name !== 'local',
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        ...MAT_NATIVE_DATE_FORMATS,
        parse: {
          ...MAT_NATIVE_DATE_FORMATS.parse,
          dateInput: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },
        },
        display: {
          ...MAT_NATIVE_DATE_FORMATS.display,
          dateInput: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          },
        },
      }
    },
    {
      provide: DateAdapter,
      useClass: AebDateAdapter,
    },
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: stateActualizerBeforeInitFactory,
      deps: [
        Store,
        RollbarService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: langInitializerFactory,
      deps: [
        TranslateService,
        DateAdapter,
        Store,
        DOCUMENT,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: servicesInitializerFactory,
      deps: [
        ActionsWatcherService,
      ],
      multi: true,
    },
    {
      provide: ActiveCompanyService,
      useFactory: () => {
        const permissionsService: PermissionsService = inject(PermissionsService);
        const userActiveCompanyService: ActiveCompanyService = inject(UserActiveCompanyService);
        const suActiveCompanyService: ActiveCompanyService = inject(SuActiveCompanyService);

        // Do not use PermissionService here because of circular dependency
        if (permissionsService.canUseSuModule()) {
          return suActiveCompanyService;
        } else {
          return userActiveCompanyService;
        }
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: msalInitializerFactory,
      deps: [
        Store,
        AuthService,
        MsalService,
        MsalBroadcastService,
        OnboardingService,
        Router,
        Location,
        DOCUMENT,
        LoggingOutBlockingLoaderService,
      ],
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XEBridgeCompanyIdInterceptor,
      deps: [
        ActiveCompanyService,
      ],
      multi: true,
    },
    {
      provide: NGXS_PLUGINS,
      useValue: clearStatePlugin,
      multi: true,
    },
    {
      provide: NGXS_PLUGINS,
      useClass: NgxsCustomLoggerPlugin,
      multi: true,
    },
    {
      provide: DEFAULT_ANIMATED_DIALOG_CONFIG,
      useValue: {
        ...(new AnimatedDialogConfig()),
        enterAnimation: {
          animation: FADE_IN_ANIMATION,
          options: {
            params: {
              delay: 0,
              duration: DEFAULT_ANIMATION_DURATION,
            },
          }
        },
        leaveAnimation: {
          animation: FADE_OUT_ANIMATION,
          options: {
            params: {
              delay: 0,
              duration: DEFAULT_ANIMATION_DURATION,
            },
          }
        },
      },
    },
  ],
  bootstrap: [
    AppComponent,
    ToastsComponent,
  ],
})
export class AppModule { }
