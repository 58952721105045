import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'aeb-form-lines',
  templateUrl: './form-lines.component.html',
  styleUrls: ['./form-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLinesComponent { }
