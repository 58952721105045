import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Toast, ToastPreparator, ToastSample, ToastStatus, TOAST_PREPARATOR } from './toasts';

@Injectable({
  providedIn: 'root',
})
export class ToastsService {

  private readonly toasts$$: BehaviorSubject<Toast[]> = new BehaviorSubject<Toast[]>([]);
  readonly toasts$: Observable<Toast[]> = this.toasts$$.asObservable();

  constructor(@Inject(TOAST_PREPARATOR) private toastPreparator: ToastPreparator) { }

  private addToast(toastSample: ToastSample): Toast['id'] {
    const toast: Toast = this.toastPreparator.prepare(toastSample);

    this.toasts$$.next([...this.toasts$$.value, toast]);

    return toast.id;
  }

  error(toastSample: Omit<ToastSample, 'status'>): Toast['id'] {
    return this.addToast({
      ...toastSample,
      status: ToastStatus.ERROR,
    });
  }

  success(toastSample: Omit<ToastSample, 'status'>): Toast['id'] {
    return this.addToast({
      ...toastSample,
      status: ToastStatus.SUCCESS,
    });
  }

  warning(toastSample: Omit<ToastSample, 'status'>): Toast['id'] {
    return this.addToast({
      ...toastSample,
      status: ToastStatus.WARNING,
    });
  }

  info(toastSample: Omit<ToastSample, 'status'>): Toast['id'] {
    return this.addToast({
      ...toastSample,
      status: ToastStatus.INFO,
    });
  }

  removeAll(): void {
    this.toasts$$.next([]);
  }

  removeToast(toastId: Toast['id']): void {
    this.toasts$$.next(this.toasts$$.value.filter((toast: Toast) => toast.id !== toastId));
  }

}
