import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { User } from '@Libs/model';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  @Select(DashboardState.user) readonly user$!: Observable<User>;
  @Select(DashboardState.userInitials) readonly userInitials$!: Observable<string>;

}
