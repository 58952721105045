<div class="container-fluid">
  <div class="layout-logo-wrapper">
    <a class="layout-logo-link" routerLink="/dashboard" [attr.title]="appTitle">
      <picture>
        <img class="layout-logo img-fluid" src="/assets/logo.svg" width="255" height="36" [attr.alt]="appTitle">
      </picture>
    </a>
  </div>

  <router-outlet></router-outlet>
</div>
