import { PayoutSummaryBookkeepingReportListResponse, PayoutTransactionReportListResponse } from '@Libs/model';
import {
  DEFAULT_PAYOUT_REPORTS_SORT_DIRECTION_TYPE,
  DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_SORT_KEY_TYPE,
  DEFAULT_PAYOUT_TRANSACTION_REPORTS_SORT_KEY_TYPE,
  INITIAL_PAYOUT_REPORTS_AMOUNT,
  PayoutSummaryBookkeepingReportListOptions,
  PayoutTransactionReportListOptions,
} from '@WebUi/settlements/models/settlements.model';

export interface SettlementsStateModel {
  payoutTransactionReports: PayoutTransactionReportListResponse | null;
  payoutTransactionReportsOptions: PayoutTransactionReportListOptions;
  payoutSummaryBookkeepingReports: PayoutSummaryBookkeepingReportListResponse | null;
  payoutSummaryBookkeepingReportsOptions: PayoutSummaryBookkeepingReportListOptions;
}

export const SETTLEMENTS_STATE_DEFAULTS: SettlementsStateModel = {
  payoutTransactionReports: null,
  payoutTransactionReportsOptions: {
    sortDirection: DEFAULT_PAYOUT_REPORTS_SORT_DIRECTION_TYPE,
    sortKey: DEFAULT_PAYOUT_TRANSACTION_REPORTS_SORT_KEY_TYPE,
    dateRangeOption: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    searchKey: '',
    status: [],
    connectionId: [],
    paymentProvider: [],
    offset: 0,
    pageSize: INITIAL_PAYOUT_REPORTS_AMOUNT,
  },
  payoutSummaryBookkeepingReports: null,
  payoutSummaryBookkeepingReportsOptions: {
    sortDirection: DEFAULT_PAYOUT_REPORTS_SORT_DIRECTION_TYPE,
    sortKey: DEFAULT_PAYOUT_SUMMARY_BOOKKEEPING_REPORTS_SORT_KEY_TYPE,
    dateRangeOption: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    searchKey: '',
    status: [],
    connectionId: [],
    paymentProvider: [],
    offset: 0,
    pageSize: INITIAL_PAYOUT_REPORTS_AMOUNT,
  },
};
