import { Routes } from '@angular/router';
import { StartupComponent } from '@WebUi/app/pages/startup/startup.component';
import { SuStartupComponent } from '@WebUi/app/pages/su-startup/su-startup.component';
import { DevStartupComponent } from '@WebUi/app/pages/dev-startup/dev-startup.component';
import { NotFoundComponent } from '@WebUi/app/pages/not-found/not-found.component';
import { ShopifyConnectionTokenHandlerComponent } from '@WebUi/app/pages/shopify-connection-token-handler/shopify-connection-token-handler.component';
import { ShopifyConnectionTokenHandlerGuard } from '@WebUi/app/guards/shopify-connection-token-handler.guard';
import { LayoutComponent } from '@WebUi/app/layouts/layout/layout.component';
import { LayoutLoginComponent } from '@WebUi/app/layouts/layout-login/layout-login.component';
import { LoadChildrenCallbackReturnType } from '@WebUi/helpers/load-children-type';
import { ZettleConnectionHandlerComponent } from '@WebUi/app/pages/zettle-connection-handler/zettle-connection-handler.component';
import { ZettleConnectionHandlerGuard } from '@WebUi/app/guards/zettle-connection-handler.guard';
import { IndexComponent } from '@WebUi/app/pages/index/index.component';
import { IndexGuard } from '@WebUi/app/guards/index.guard';
import { SilentAuthComponent } from '@WebUi/app/pages/silent-auth/silent-auth.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutLoginComponent,
    children: [
      {
        path: '',
        canActivate: [
          IndexGuard,
        ],
        component: IndexComponent,
      },
      {
        path: 'login',
        component: StartupComponent,
      },
      {
        path: 'su',
        component: SuStartupComponent,
      },
      {
        path: 'dev',
        component: DevStartupComponent,
      },
    ],
  },
  {
    path: '',
    loadChildren: (): LoadChildrenCallbackReturnType => {
      return import('@WebUi/dashboard/dashboard.module').then((m) => m.DashboardModule);
    },
  },
  {
    path: 'onboarding',
    loadChildren: (): LoadChildrenCallbackReturnType => {
      return import('@WebUi/onboarding/onboarding.module').then((m) => m.OnboardingModule);
    },
  },
  {
    path: '',
    component: LayoutComponent,
    loadChildren: (): LoadChildrenCallbackReturnType => {
      return import('@WebUi/app/app-deferred.module').then((m) => m.AppDeferredModule);
    },
  },
  {
    path: 'shopify-connection-token-handler',
    canActivate: [
      ShopifyConnectionTokenHandlerGuard,
    ],
    component: ShopifyConnectionTokenHandlerComponent,
  },
  {
    path: 'zettle-connection-handler',
    canActivate: [
      ZettleConnectionHandlerGuard,
    ],
    component: ZettleConnectionHandlerComponent,
  },
  {
    path: 'silent-auth',
    component: SilentAuthComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
