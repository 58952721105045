import { SetupWizardOnboardingSnapshot, SetupWizardSalesChannelFlowState, SetupWizardPaymentProviderFlowState } from '@WebUi/setup-wizard/models/setup-wizard.model';

export class PrepareSetupWizardStateWithOnboardingSnapshot {
  static readonly actionName = 'PrepareSetupWizardStateWithOnboardingSnapshot';
  static readonly type = '[SetupWizard] Prepare setup wizard state with onboarding snapshot';

  constructor(public setupWizardOnboardingSnapshot: SetupWizardOnboardingSnapshot) { }
}

export class StoreSetupWizardSalesChannelFlowState {
  static readonly actionName = 'StoreSetupWizardSalesChannelFlowState';
  static readonly type = '[SetupWizard] Store setup wizard sales channel flow state';

  constructor(public flowState: Partial<SetupWizardSalesChannelFlowState>) { }
}

export class StoreSetupWizardPaymentProviderFlowState {
  static readonly actionName = 'StoreSetupWizardPaymentProviderFlowState';
  static readonly type = '[SetupWizard] Store setup wizard payment vendor flow state';

  constructor(public flowState: Partial<SetupWizardPaymentProviderFlowState>) { }
}

export class ResetSetupWizard {
  static readonly actionName = 'ResetSetupWizard';
  static readonly type = '[SetupWizard] Reset setup wizard';
}
