import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { APP_ROUTES } from '@WebUi/app/app.routes';
import { environment } from '@WebUi/env';

@NgModule({
  imports: [
    RouterModule.forRoot(
      APP_ROUTES,
      {
        useHash: false,
        preloadingStrategy: PreloadAllModules,
        // TODO try to use them later
        // onSameUrlNavigation: 'reload',
        // paramsInheritanceStrategy: 'always',
        enableTracing: environment.logging.routing,
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
