import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '@WebUi/app/services/auth.service';
import { MsalBroadcastService, MsalCustomNavigationClient, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, AuthError } from '@azure/msal-common';
import { Store } from '@ngxs/store';
import { BrowserAuthOptions, BrowserCacheLocation, CacheOptions, Configuration, EventMessage, EventType, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { OnboardingService } from '@WebUi/onboarding/services/onboarding.service';
import { API_CONFIGS, AUTHORITY_FOR_ONBOARDING, DEFAULT_AUTHORITY, MsalApiConfig } from '@WebUi/app/models/app.model';
import { ClearState } from '@WebUi/app/store/app.actions';
import { LoggingOutBlockingLoaderService } from '@WebUi/app/services/logging-out-blocking-loader.service';
import { catchError } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { environment } from '@WebUi/env';

export function msalInitializerFactory(
  store: Store,
  authService: AuthService,
  msalService: MsalService,
  // We have to inject MsalBroadcastService to execute code inside its contructor
  msalBroadcastService: MsalBroadcastService,
  // We have to inject OnboardingService to execute code inside its contructor
  onboardingService: OnboardingService,
  router: Router,
  location: Location,
  document: Document,
  loggingOutBlockingLoaderService: LoggingOutBlockingLoaderService,
): () => Promise<any> {
  return async (): Promise<any> => {
    const customNavigationClient = new MsalCustomNavigationClient(msalService, router, location);

    msalService.instance.setNavigationClient(customNavigationClient);

    msalService.instance.enableAccountStorageEvents();

    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/events.md
    msalBroadcastService.msalSubject$
      .subscribe((message: EventMessage) => {
        if (message.eventType === EventType.ACCOUNT_ADDED || message.eventType === EventType.ACCOUNT_REMOVED) {
          // Syncing logged in state across tabs and windows
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md#syncing-logged-in-state-across-tabs-and-windows
          document.location.reload();

          return;
        }

        // loginRedirect method emits LOGIN_SUCCESS or ACQUIRE_TOKEN_SUCCESS event depending on amount of accounts in storage
        // if new account appears - it emits LOGIN_SUCCESS, if amount of accounts did not change - it emits ACQUIRE_TOKEN_SUCCESS
        if (message.eventType === EventType.LOGIN_SUCCESS) {
          const authenticationResult: AuthenticationResult = message.payload as AuthenticationResult;

          if (authenticationResult.account) {
            msalService.instance.setActiveAccount(authenticationResult.account);
          }

          return;
        }

        if (message.eventType === EventType.LOGIN_FAILURE) {
          // AADB2C90091: ServerError: 'access_denied'. The user has cancelled entering self-asserted information
          // User clicked 'Cancel' button on registration, forgot/reset pasword or edit profile page - redirect back to login on AD B2C side
          if (message.error && (message.error instanceof AuthError) && message.error.errorMessage.includes('AADB2C90091')) {
            authService.login({
              authority: document.location.pathname.startsWith('/onboarding') ? AUTHORITY_FOR_ONBOARDING : DEFAULT_AUTHORITY,
              redirectStartPage: `${document.location.pathname}${document.location.search}`,
            })
              .subscribe();

            return;
          }

          // Any other login failure - redirect to local login page
          router.navigateByUrl('/');

          return;
        }

        // loginRedirect method emits LOGIN_SUCCESS or ACQUIRE_TOKEN_SUCCESS event depending on amount of accounts in storage
        // if new account appears - it emits LOGIN_SUCCESS, if amount of accounts did not change - it emits ACQUIRE_TOKEN_SUCCESS
        if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          const authenticationResult: AuthenticationResult = message.payload as AuthenticationResult;

          if (authenticationResult.account) {
            msalService.instance.setActiveAccount(authenticationResult.account);
          }

          return;
        }

        if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          // AADB2C90080: The provided grant has expired. Please re-authenticate and try again.
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md#refresh-tokens
          // Refresh token expired - try to reauthenticate user
          // if (message.error && (message.error instanceof AuthError) && message.error.errorMessage.includes('AADB2C90080')) {
          //   authService.login({
          //     authority: authService.getActiveAccountAuthority() ?? DEFAULT_AUTHORITY,
          //     redirectStartPage: `${document.location.pathname}${document.location.search}`,
          //   })
          //     .subscribe();
          // }

          authService.logout()
            .subscribe();

          return;
        }

        if (message.eventType === EventType.LOGOUT_START) {
          // We should not redirect user here, msal.js makes redirect to postLogoutRedirectUri
          loggingOutBlockingLoaderService.start();

          store.dispatch([new ClearState()]);

          return;
        }

        if (message.eventType === EventType.LOGOUT_FAILURE) {
          // Logout failure - manualy redirect to local login page
          router.navigateByUrl('/login')
            .then(() => {
              // There was not any redirects so stop loader manualy
              loggingOutBlockingLoaderService.stop();
            });

          return;
        }
      });

    await firstValueFrom(msalService.handleRedirectObservable()
      .pipe(
        // Catch errors here to not interrupt app loading
        // We handle errors in msalBroadcastService.msalSubject$ subscription above
        catchError(() => of(null)),
      ));
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    loginFailedRoute: '/',
  };
};

export function MSALInstanceFactory(auth?: BrowserAuthOptions, cache?: CacheOptions): PublicClientApplication {
  const config: Configuration = {
    auth: auth ?? {
      clientId: environment.azure.clientId,
      authority: DEFAULT_AUTHORITY,
      knownAuthorities: [environment.azure.authorityDomain],
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true,
    },
    cache: cache ?? {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  };

  if (environment.logging.msal) {
    config.system = {
      loggerOptions: {
        loggerCallback,
        piiLoggingEnabled: true,
        logLevel: LogLevel.Verbose,
      },
    };
  }

  return new PublicClientApplication(config);
}

export function loggerCallback(level: LogLevel, message: string): void {
  console.log(message);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  API_CONFIGS.forEach((apiConfig: MsalApiConfig) => {
    protectedResourceMap.set(apiConfig.uri, apiConfig.scopes);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
