export enum SortDirectionType {
  ASC = 0,
  DESC = 1,
}

export const SORT_DIRECTION_TYPES: SortDirectionType[] = [
  SortDirectionType.ASC,
  SortDirectionType.DESC,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSortDirectionType(sortDirectionType: any): sortDirectionType is SortDirectionType {
  return SORT_DIRECTION_TYPES.includes(sortDirectionType);
}

export enum DateRangeOption {
  TODAY = 'TODAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_THREE_MONTHS = 'LAST_THREE_MONTHS',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM_DATE_RANGE = 'CUSTOM_DATE_RANGE',
}

export const DATE_RANGE_OPTIONS: DateRangeOption[] = [
  DateRangeOption.TODAY,
  DateRangeOption.LAST_WEEK,
  DateRangeOption.LAST_MONTH,
  DateRangeOption.LAST_THREE_MONTHS,
  DateRangeOption.LAST_YEAR,
  DateRangeOption.CUSTOM_DATE_RANGE,
];

export interface DateRange {
  dateRangeStart: number | null;
  dateRangeEnd: number | null;
}

export interface DateRangeFilterOptions extends DateRange {
  dateRangeOption: DateRangeOption | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isDateRangeOption(dateRangeOption: any): dateRangeOption is DateRangeOption {
  return DATE_RANGE_OPTIONS.includes(dateRangeOption);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isPredefinedDateRangeOption(dateRangeOption: any): boolean {
  return isDateRangeOption(dateRangeOption) && dateRangeOption !== DateRangeOption.CUSTOM_DATE_RANGE;
}

export function convertPredefinedDateRangeToDateFromSeconds(dateRangeOption: DateRangeOption): number {
  if (dateRangeOption === DateRangeOption.CUSTOM_DATE_RANGE) {
    throw new Error('Can not convert custom date range option to timestamp');
  }

  const result = new Date();

  switch (dateRangeOption) {
    case DateRangeOption.TODAY:
      break;
    case DateRangeOption.LAST_WEEK:
      result.setUTCDate(result.getUTCDate() - 7);
      break;
    case DateRangeOption.LAST_MONTH:
      result.setUTCMonth(result.getUTCMonth() - 1);
      break;
    case DateRangeOption.LAST_THREE_MONTHS:
      result.setUTCMonth(result.getUTCMonth() - 3);
      break;
    case DateRangeOption.LAST_YEAR:
      result.setUTCMonth(result.getUTCMonth() - 12);
      break;
  }

  result.setUTCHours(0, 0, 0, 0);

  return Math.floor(result.valueOf() / 1000);
}

export function handleDateRange(dateRangeFilterOptions: DateRangeFilterOptions): DateRange {
  let dateRangeStart: number | null = null;
  let dateRangeEnd: number | null = null;

  if (dateRangeFilterOptions.dateRangeOption) {
    if (isPredefinedDateRangeOption(dateRangeFilterOptions.dateRangeOption)) {
      dateRangeStart = convertPredefinedDateRangeToDateFromSeconds(dateRangeFilterOptions.dateRangeOption);
    } else {
      if (dateRangeFilterOptions.dateRangeStart) {
        dateRangeStart = dateRangeFilterOptions.dateRangeStart;
      }

      if (dateRangeFilterOptions.dateRangeEnd) {
        dateRangeEnd = dateRangeFilterOptions.dateRangeEnd + 86399;
      }
    }
  }

  return {
    dateRangeStart,
    dateRangeEnd,
  };
}

export interface ReportListRequestBase extends DateRange {
  offset: number;
  pageSize: number;
}

export interface Pagination {
  count: number;
  offset: number;
  total: number;
}

export interface ReportListResponseBase {
  pagination: Pagination;
}
