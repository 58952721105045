import { ID, Agreement, UnimicroPlatformCompany, UnimicroPlatformCompanyDescriptor } from '@Libs/model';
import { OnboardingShopifyToken, OnboardingInput, OnboardingFlowStateRequiredData } from '@WebUi/onboarding/models/onboarding.model';

export class StoreOnboardingInput {
  static readonly actionName = 'StoreOnboardingInput';
  static readonly type = '[Onboarding] Store onboarding input';

  constructor(public input: OnboardingInput) { }
}

export class StoreOnboardingAgreement {
  static readonly actionName = 'StoreOnboardingAgreement';
  static readonly type = '[Onboarding] Store onboarding agreement';

  constructor(public agreement: Agreement) { }
}

export class PrepareOnboardingFlow {
  static readonly actionName = 'PrepareOnboardingFlow';
  static readonly type = '[Onboarding] Prepare onboarding flow';

  constructor(public flowStateRequiredData: OnboardingFlowStateRequiredData) { }
}

export class ResetOnboardingFlow {
  static readonly actionName = 'ResetOnboardingFlow';
  static readonly type = '[Onboarding] Reset onboarding flow';
}

export class ResetOnboardingInput {
  static readonly actionName = 'ResetOnboardingInput';
  static readonly type = '[Onboarding] Reset onboarding input';
}

export class StoreOnboardingCompanyId {
  static readonly actionName = 'StoreOnboardingCompanyId';
  static readonly type = '[Onboarding] Store onboarding company';

  constructor(public companyId: ID) { }
}

export class StoreOnboardingUnimicroPlatformCompany {
  static readonly actionName = 'StoreOnboardingUnimicroPlatformCompany';
  static readonly type = '[Onboarding] Store onboarding Unimicro Platform company';

  constructor(public unimicroPlatformCompany: UnimicroPlatformCompany) { }
}

export class StoreOnboardingUnimicroPlatformCompanies {
  static readonly actionName = 'StoreOnboardingUnimicroPlatformCompanies';
  static readonly type = '[Onboarding] Store onboarding Unimicro Platform companies';

  constructor(public unimicroPlatformCompanies: UnimicroPlatformCompanyDescriptor[]) { }
}

export class StoreOnboardingUnimicroPlatformCompanyKey {
  static readonly actionName = 'StoreOnboardingUnimicroPlatformCompanyKey';
  static readonly type = '[Onboarding] Store onboarding Unimicro Platform company key';

  constructor(public unimicroPlatformCompanyKey: string) { }
}

export class ResetOnboardingUnimicroPlatformCompanyKey {
  static readonly actionName = 'ResetOnboardingUnimicroPlatformCompanyKey';
  static readonly type = '[Onboarding] Reset onboarding Unimicro Platform company key';
}

export class StoreOnboardingShopifyToken {
  static readonly actionName = 'StoreOnboardingShopifyToken';
  static readonly type = '[Onboarding] Store onboarding shopify token';

  constructor(public shopifyToken: OnboardingShopifyToken) { }
}

export class ResetOnboardingShopifyToken {
  static readonly actionName = 'ResetOnboardingShopifyToken';
  static readonly type = '[Onboarding] Reset onboarding shopify token';
}

export class ResetOnboarding {
  static readonly actionName = 'ResetOnboarding';
  static readonly type = '[Onboarding] Reset onboarding';
}
