import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DEFAULT_SVG_ICONS_MODULE_OPTIONS, SVG_ICONS_MODULE_OPTIONS_TOKEN } from './svg-icons';
import { HttpClientModule } from '@angular/common/http';
import { SvgIconComponent } from './svg-icon/svg-icon.component';

@NgModule({
  declarations: [
    SvgIconComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: SVG_ICONS_MODULE_OPTIONS_TOKEN,
      useValue: DEFAULT_SVG_ICONS_MODULE_OPTIONS,
    },
  ],
  exports: [
    SvgIconComponent,
  ],
})
export class SvgIconsModule { }
