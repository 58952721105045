import { DateRangeOption, ProductOperationReportListRequest, ProductRecentReportListRequest } from '@Libs/model';

export const INITIAL_LOGS_AMOUNT = 50;

export interface RecentReportListOptions extends ProductRecentReportListRequest {
  dateRangeOption: DateRangeOption | null;
}

export interface OperationReportListOptions extends ProductOperationReportListRequest {
  dateRangeOption: DateRangeOption | null;
}
