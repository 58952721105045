import { InvoiceReport } from '@Libs/model';
import { InvoiceReportListOptions } from '@WebUi/invoices/models/invoices.model';

export class GetSortedInvoiceReports {
  static readonly actionName = 'GetSortedInvoiceReports';
  static readonly type = '[Invoices] Get sorted invoice reports';
}

export class LoadMoreInvoiceReports {
  static readonly actionName = 'LoadMoreInvoiceReports';
  static readonly type = '[Invoices] Load more invoice reports';
}

export class UpdateInvoiceReportListOptions {
  static readonly actionName = 'UpdateInvoiceReportListOptions';
  static readonly type = '[Invoices] Update invoice report list options';

  constructor(public options: Partial<InvoiceReportListOptions>) { }
}

export class ResetInvoiceReportListOptions {
  static readonly actionName = 'ResetInvoiceReportListOptions';
  static readonly type = '[Invoices] Reset invoice report list options';
}

export class AddTemporaryPendingInvoiceReport {
  static readonly actionName = 'AddTemporaryPendingInvoiceReport';
  static readonly type = '[Invoices] Add temporary pending invoice report';

  constructor(public temporaryPendingInvoiceReport: InvoiceReport) { }
}

export class ForgetTemporaryPendingInvoiceReport {
  static readonly actionName = 'ForgetTemporaryPendingInvoiceReport';
  static readonly type = '[Invoices] Forget temporary pending invoice report';

  constructor(public invoiceId: number) { }
}
