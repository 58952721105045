import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'aeb-form-description',
  templateUrl: './form-description.component.html',
  styleUrls: ['./form-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDescriptionComponent { }
