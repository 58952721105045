import { AnimationBuilder, AnimationPlayer } from '@angular/animations';
import { DialogCloseOptions, DialogRef } from '@angular/cdk/dialog';
import { BasePortalOutlet } from '@angular/cdk/portal';
import { AnimatedDialogConfig } from './animated-dialog-config';
import { OverlayRef } from '@angular/cdk/overlay';
import { combineLatest, fromEvent, merge, Observable, Subject, Subscriber, take, timer } from 'rxjs';

export class AnimatedDialogRef<R = unknown, C = unknown> extends DialogRef<R, C> {

  constructor(
    readonly _overlayRef: OverlayRef,
    readonly _config: AnimatedDialogConfig<any, DialogRef<R, C>, BasePortalOutlet>,
    private _animationBuilder: AnimationBuilder,
  ) {
    super(_overlayRef, _config);
  }

  override close(result?: R, options?: DialogCloseOptions): void {
    if (this.containerInstance) {
      this.containerInstance._closeInteractionType = options?.focusOrigin || 'program';
      // Drop the detach subscription first since it can be triggered by the
      // `dispose` call and override the result of this closing sequence.
      (this as any)._detachSubscription.unsubscribe();

      if ((this.overlayRef as any)._animationsDisabled) {
        this._completeClosing(result);
      } else {
        if (this._config.leaveAnimation) {
          const leaveAnimationPlayer: AnimationPlayer = this._animationBuilder.build(this._config.leaveAnimation.animation).create((this as any).containerInstance._elementRef.nativeElement, this._config.leaveAnimation.options);

          const leaveAnimationObservable$: Observable<void> = new Observable((subscriber: Subscriber<void>) => {
            leaveAnimationPlayer.onDone(() => {
              subscriber.next();
            });

            leaveAnimationPlayer.onDestroy(() => {
              subscriber.complete();
            });
          });

          if (this.overlayRef.backdropElement) {
            combineLatest([
              merge(
                // hardcoded timeout inside detachBackdrop method
                timer(500),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                fromEvent(this.overlayRef.backdropElement!, 'transitionend'),
              ),
              leaveAnimationObservable$,
            ])
              .pipe(
                take(1),
              )
              .subscribe(() => {
                this._completeClosing(result);

                leaveAnimationPlayer.destroy();
              });

            leaveAnimationPlayer.play();

            this.overlayRef.detachBackdrop();
          } else {
            leaveAnimationObservable$
              .pipe(
                take(1),
              )
              .subscribe(() => {
                this._completeClosing(result);

                leaveAnimationPlayer.destroy();
              });

            leaveAnimationPlayer.play();
          }
        } else {
          if (this.overlayRef.backdropElement) {
            merge(
              // hardcoded timeout inside detachBackdrop method
              timer(500),
              fromEvent(this.overlayRef.backdropElement, 'transitionend'),
            )
              .pipe(
                take(1),
              )
              .subscribe(() => {
                this._completeClosing(result);
              });
          } else {
            this._completeClosing(result);
          }

          this.overlayRef.detach();
        }
      }
    }
  }

  private _completeClosing(result?: R): void {
    // Dispose when all animation completed
    this.overlayRef.dispose();

    (this.closed as Subject<R | undefined>).next(result);
    (this.closed as Subject<R | undefined>).complete();
    (this as { componentInstance: C }).componentInstance = (
      this as { containerInstance: BasePortalOutlet }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ).containerInstance = null!;
  }

}
