import { Lang } from '@Libs/model';

export class ClearState {
  static readonly actionName = 'ClearState';
  static readonly type = '[App] ClearState';
}

export class SetLang {
  static readonly actionName = 'SetLang';
  static readonly type = '[App] Set lang';

  constructor(public lang: Lang) { }
}

export class StoreAppVersion {
  static readonly actionName = 'StoreAppVersion';
  static readonly type = '[App] Store app version';

  constructor(public appVersion: string) { }
}
