import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'aeb-pulse-dots-progress',
  templateUrl: './pulse-dots-progress.component.html',
  styleUrls: ['./pulse-dots-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PulseDotsProgressComponent { }
