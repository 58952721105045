import { DateRangeOption, ExternalSystemTypeKind, ID, User } from '@Libs/model';
import { DashboardSettings, DashboardSidebarToggleState } from '@WebUi/dashboard/models/dashboard.model';

export interface DashboardStateModel {
  activeUserCompanyId: ID | null;
  dashboardSettings: DashboardSettings,
  externalSystemTypeKinds: ExternalSystemTypeKind[] | null;
  user: User;
}

export const DASHBOARD_STATE_DEFAULTS: DashboardStateModel = {
  activeUserCompanyId: null,
  dashboardSettings: {
    showDemoData: false,
    sidebarToggleState: window && window.matchMedia && window.matchMedia('(max-width: 1280px)').matches ? DashboardSidebarToggleState.COLLAPSED : DashboardSidebarToggleState.EXPANDED,
    widgets: {
      transactions: {
        range: DateRangeOption.LAST_WEEK,
      },
    },
  },
  externalSystemTypeKinds: null,
  user: {
    id: '',
    fullName: '',
    email: '',
    companies: [],
  },
};
