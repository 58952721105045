import { DateRangeOption } from '@Libs/model';
import { DEFAULT_DATE_FORMAT } from '@WebUi/helpers/date';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';

export interface DashboardSettings {
  showDemoData: boolean;
  sidebarToggleState: DashboardSidebarToggleState;
  widgets: {
    transactions: TransactionsWidgetSettings;
  };
}

export enum DashboardSidebarToggleState {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}

export interface DashboardLayoutSettings {
  showLogoHyperlink: boolean;
  showSidebar: boolean;
  showCompanyChooser: boolean;
  showLanguageChooser: boolean;
  showHelpBtn: boolean;
  showLogoutBtn: boolean;
  showUserProfile: boolean;
  showNotifications: boolean;
}

export enum CompanyChooserMode {
  REGULAR = 0,
  ONBOARDING = 1,
  READONLY = 2,
  SETUP_WIZARD = 3,
}

export interface CompanyChooserSettings {
  mode: CompanyChooserMode;
  canChangeActiveCompany$: Observable<boolean>;
}

export interface TransactionsWidgetSettings {
  range: DateRangeOption;
}

export interface SavedTime {
  hours: number;
  minutes: number;
}

export function convertOperationsToSavedHours(amountOfInvoices: number, amountOfTransactions: number): number {
  const handledOperations: number = amountOfInvoices + amountOfTransactions;
  const savedMinutes: number = handledOperations * 2.5; // 1 operation = 2.5 min
  const savedHours: number = savedMinutes / 60;

  return savedHours;
}

export function convertSavedHoursToSavedTime(hours: number): SavedTime {
  const minutes: number = Math.round(hours * 60);

  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
}

export function generateInvoicesAmountTooltip(timestampInMilliseconds: number, invoicesAmount: number): string {
  return `<div class='google-visualization-tooltip-inner'><div>${formatDate(timestampInMilliseconds, DEFAULT_DATE_FORMAT, 'en', '+0000')}</div><div class='accent'>${invoicesAmount}</div></div>`;
}

export function generateTransactionsAmountTooltip(timestampInMilliseconds: number, transactionsAmount: number): string {
  return `<div class='google-visualization-tooltip-inner'><div>${formatDate(timestampInMilliseconds, DEFAULT_DATE_FORMAT, 'en', '+0000')}</div><div class='accent'>${transactionsAmount}</div></div>`;
}
