import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoaderService implements OnDestroy {

  private counter = 0;

  private readonly loading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loading$: Observable<boolean> = this.loading$$.asObservable();

  start(): void {
    this.counter++;

    this.loading$$.next(true);
  }

  stop(): void {
    this.counter--;

    this.loading$$.next(this.counter > 0);
  }

  ngOnDestroy(): void {
    this.loading$$.next(false);
    this.loading$$.complete();
  }

}
