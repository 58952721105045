import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { uuid } from '@WebUi/helpers/uuid';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestIdInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      headers: request.headers.set('RequestId', uuid()),
    });

    return next.handle(modifiedRequest);
  }

}
