import { OrderEventReportsResponse } from '@Libs/model';
import { INITIAL_ORDER_EVENT_AMOUNT, OrderEventReportsOptions } from '@WebUi/orders/models/orders.model';

export interface OrdersStateModel {
  orderEventReportsResponse: OrderEventReportsResponse | null;
  orderEventReportsOptions: OrderEventReportsOptions;
}

export const ORDERS_STATE_DEFAULTS: OrdersStateModel = {
  orderEventReportsResponse: null,
  orderEventReportsOptions: {
    originOrderId: null,
    dateRangeOption: null,
    dateRangeStart: null,
    dateRangeEnd: null,
    offset: 0,
    pageSize: INITIAL_ORDER_EVENT_AMOUNT,
  },
};
