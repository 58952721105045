import { OperationReportListOptions, RecentReportListOptions } from '@WebUi/product-sync/models/product-sync.model';

export class GetProductRecentReports {
  static readonly actionName = 'GetProductRecentReports';
  static readonly type = '[ProductSync] Get product recent reports';
}

export class LoadMoreRecentReports {
  static readonly actionName = 'LoadMoreRecentReports';
  static readonly type = '[ProductSync] Load more recent reports';
}

export class GetProductOperationReports {
  static readonly actionName = 'GetProductOperationReports';
  static readonly type = '[ProductSync] Get product operation reports';
}

export class LoadMoreOperationReports {
  static readonly actionName = 'LoadMoreOperationReports';
  static readonly type = '[ProductSync] Load more operation reports';
}

export class UpdateProductRecentReportsOptions {
  static readonly actionName = 'UpdateProductRecentReportsOptions';
  static readonly type = '[ProductSync] Update product recent reports options';

  constructor(public options: Partial<RecentReportListOptions>) { }
}

export class UpdateProductOperationReportsOptions {
  static readonly actionName = 'UpdateProductOperationReportsOptions';
  static readonly type = '[ProductSync] Update product operation reports options';

  constructor(public options: Partial<OperationReportListOptions>) { }
}
