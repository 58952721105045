import { ChangeDetectionStrategy, Component, HostBinding, Inject, Injector, Input } from '@angular/core';
import { SvgIconAbstract } from '@Libs/svg-icons';
import {
  Heroicon,
  HeroiconStyle,
  HeroiconStyleType,
  HeroiconType,
  HEROICONS_MODULE_OPTIONS_TOKEN,
  HeroiconsModuleOptions,
} from '../heroicons';

@Component({
  selector: 'heroicon',
  templateUrl: './heroicon.component.html',
  styleUrls: ['./heroicon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroiconComponent extends SvgIconAbstract {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('icon') heroiconName!: Heroicon | HeroiconType;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('type') heroiconStyle: HeroiconStyle | HeroiconStyleType = this.heroiconsModuleOptions.defaultStyle;

  @HostBinding('class') override cssClasses!: string;

  constructor(
    @Inject(HEROICONS_MODULE_OPTIONS_TOKEN) private heroiconsModuleOptions: HeroiconsModuleOptions,
    injector: Injector,
  ) {
    super(injector);
  }

  get className(): string {
    return this.heroiconsModuleOptions.classNameFactory(this.heroiconStyle, this.heroiconName);
  }

  get svgIconPath(): string {
    return this.heroiconsModuleOptions.svgIconPathFactory(this.heroiconStyle, this.heroiconName);
  }

}
