import { InjectionToken } from '@angular/core';
import { DEFAULT_ROLLBAR_CONFIG } from '@WebUi/app/configs/rollbar.config';
import type Rollbar from 'rollbar';

export const ROLLBAR_CONFIG: InjectionToken<Rollbar.Configuration> = new InjectionToken<Rollbar.Configuration>(
  'Rollbar configuration',
  {
    providedIn: 'root',
    factory: () => DEFAULT_ROLLBAR_CONFIG,
  },
);

export const APP_TITLE = new InjectionToken<string>(
  'App title',
  {
    providedIn: 'root',
    factory: () => 'Unimicro Bridge',
  },
);

export const APP_SHORT_TITLE = new InjectionToken<string>(
  'App short title',
  {
    providedIn: 'root',
    factory: () => 'Bridge',
  },
);

export const SUPPORT_EMAIL = new InjectionToken<string>(
  'Support email',
  {
    providedIn: 'root',
    factory: () => 'support@netthandel.unimicro.no',
  },
);

export const HELP_CENTER_URL = new InjectionToken<string>(
  'Help center URL',
  {
    providedIn: 'root',
    factory: () => 'https://hjelp.unimicro.no/integrasjoner/unimicro-bridge',
  },
);

export const CURRENT_YEAR = new InjectionToken<number>(
  'Current year',
  {
    providedIn: 'root',
    factory: () => new Date().getFullYear(),
  },
);

export const POWERED_BY = new InjectionToken<string>(
  'Powered by',
  {
    providedIn: 'root',
    factory: () => 'Unimicro',
  },
);

export const ONBOARDING_UNIMICRO_NETTBUTIKK_SELECT_AGREEMENT_URL = new InjectionToken<string>(
  'Select agreement URL on Unimicro Nettbutikk website',
  {
    providedIn: 'root',
    factory: () => 'https://netthandel.unimicro.no/priser',
  },
);

export const ONBOARDING_UNSUPPORTED_BOOKKEEPING_MODE_FORM_URL = new InjectionToken<string>(
  'Page with contact form when user choses unsupported bookkeeping mode in onboarding',
  {
    providedIn: 'root',
    factory: () => 'https://netthandel.unimicro.no/bridge/onboarding-form',
  },
);
