import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { Locale } from '@Libs/model';

@Injectable()
export class AebDateAdapter extends NativeDateAdapter {

  override getFirstDayOfWeek(): number {
    return 1;
  }

  override getDateNames(): string[] {
    return new Array(31).fill('').map((ignore, index) => (index + 1).toString());
  }

  override getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (this.locale === Locale.NO) {
      switch (style) {
        case 'narrow':
          return ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'];
      }
    }

    if (this.locale === Locale.EN) {
      switch (style) {
        case 'narrow':
          return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
      }
    }

    return super.getDayOfWeekNames(style);
  }

  override getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (this.locale === Locale.NO) {
      switch (style) {
        case 'long':
          return [
            'januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli',
            'august', 'september', 'oktober', 'november', 'desember'
          ];
        case 'short':
          return ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
        case 'narrow':
          return ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
      }
    }

    return super.getMonthNames(style);
  }

  override parse(value: any, parseFormat: any): Date | null {
    // We have no way using the native JS Date to set the parse format or locale, so we ignore these
    // parameters.
    if (typeof value == 'number') {
      return new Date(value);
    }

    // Check if value uses format DD.MM.YYYY
    if (typeof value === 'string' && parseFormat.year === 'numeric' && parseFormat.month === '2-digit' && parseFormat.day === '2-digit') {
      const values: string[] = value.split('.');

      if (values.length === 3) {
        // Do not forget that months are 0-indexed
        return new Date(Date.UTC(parseInt(values[2], 10), parseInt(values[1], 10) - 1, parseInt(values[0], 10), 0, 0, 0, 0));
      }
    }

    return value ? new Date(Date.parse(value)) : null;
  }

  override format(date: Date, displayFormat: any): string {
    if (!this.isValid(date)) {
      throw Error('NativeDateAdapter: Cannot format invalid date.');
    }

    // Render this date format always as norwegian (format: DD.MM.YYYY)
    if (displayFormat.year === 'numeric' && displayFormat.month === '2-digit' && displayFormat.day === '2-digit') {
      const dtf: Intl.DateTimeFormat = new Intl.DateTimeFormat(
        Locale.NO,
        {
          ...displayFormat,
          timeZone: 'utc',
        },
      );

      return this._formatHelper(dtf, date);
    }

    return super.format(date, displayFormat);
  }

  private _formatHelper(dtf: Intl.DateTimeFormat, date: Date) {
    // Passing the year to the constructor causes year numbers <100 to be converted to 19xx.
    // To work around this we use `setUTCFullYear` and `setUTCHours` instead.
    const d: Date = new Date();

    d.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    d.setUTCHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

    return dtf.format(d);
  }

}
