import { animate, keyframes, style } from '@angular/animations';

export const FADE_IN_ANIMATION = animate(
  '{{duration}}ms {{delay}}ms',
  keyframes([style({ visibility: 'visible', opacity: 0, easing: 'ease', offset: 0 }), style({ opacity: 1, easing: 'ease', offset: 1 })]),
);

export const FADE_OUT_ANIMATION = animate(
  '{{duration}}ms {{delay}}ms',
  keyframes([style({ opacity: 1, easing: 'ease', offset: 0 }), style({ opacity: 0, easing: 'ease', offset: 1 })]),
);

export const SLIDE_IN_RIGHT_ANIMATION = animate(
  '{{duration}}ms {{delay}}ms',
  keyframes([
    style({ visibility: 'visible', transform: 'translate3d(100%, 0, 0)', easing: 'ease', offset: 0 }),
    style({ transform: 'translate3d(0, 0, 0)', easing: 'ease', offset: 1 })
  ]),
);

export const SLIDE_OUT_RIGHT_ANIMATION = animate(
  '{{duration}}ms {{delay}}ms',
  keyframes([
    style({ transform: 'translate3d(0, 0, 0)', easing: 'ease', offset: 0 }),
    style({ transform: 'translate3d(100%, 0, 0)', visibility: 'hidden', easing: 'ease', offset: 1 })
  ]),
);
