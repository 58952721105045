import { ExternalSystem, ID } from '@Libs/model';

export interface GeneratedConnectionNameInput {
  chosenExternalSystemTypeId: ExternalSystem,
  companyId: ID,
  amountOfCompanyConnectionsOfChosenExternalSystemType: number,
  lastConnectionIdOfChosenExternalSystemType: ID,
}

export async function generateConnectionName(input: GeneratedConnectionNameInput): Promise<string> {
  try {
    const message: string = `${input.chosenExternalSystemTypeId}${input.companyId}${input.amountOfCompanyConnectionsOfChosenExternalSystemType}${input.lastConnectionIdOfChosenExternalSystemType}`;
    const messageUint8: Uint8Array = new TextEncoder().encode(message);                      // encode as (utf-8) Uint8Array
    const hashBuffer: ArrayBuffer = await crypto.subtle.digest('SHA-256', messageUint8);     // hash the message
    const hashArray: number[] = Array.from(new Uint8Array(hashBuffer));                      // convert buffer to byte array
    const hashHex: string = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');  // convert bytes to hex string
    const shortHashHex: string = hashHex.slice(0, 8);                                        // get only first 8 symbols

    return `${input.chosenExternalSystemTypeId}-${shortHashHex}`;
  } catch (e) {
    return `${input.chosenExternalSystemTypeId}`;
  }
}
