import { PayoutSummaryBookkeepingReportListOptions, PayoutTransactionReportListOptions } from '@WebUi/settlements/models/settlements.model';

export class GetPayoutTransactionReports {
  static readonly actionName = 'GetPayoutTransactionReports';
  static readonly type = '[Settlements] Get payout transaction reports';
}

export class LoadMorePayoutTransactionReports {
  static readonly actionName = 'LoadMorePayoutTransactionReports';
  static readonly type = '[Settlements] Load more payout transaction reports';
}

export class UpdatePayoutTransactionReportsFilterOptions {
  static readonly actionName = 'UpdatePayoutTransactionReportsFilterOptions';
  static readonly type = '[Settlements] Update payout transaction reports filter options';

  constructor(public options: Partial<PayoutTransactionReportListOptions>) { }
}

export class GetPayoutSummaryBookkeepingReports {
  static readonly actionName = 'GetPayoutSummaryBookkeepingReports';
  static readonly type = '[Settlements] Get payout summary bookkeeping reports';
}

export class LoadMorePayoutSummaryBookkeepingReports {
  static readonly actionName = 'LoadMorePayoutSummaryBookkeepingReports';
  static readonly type = '[Settlements] Load more payout summary bookkeeping reports';
}

export class UpdatePayoutSummaryBookkeepingReportsFilterOptions {
  static readonly actionName = 'UpdatePayoutSummaryBookkeepingReportsFilterOptions';
  static readonly type = '[Settlements] Update payout summary bookkeeping reports filter options';

  constructor(public options: Partial<PayoutSummaryBookkeepingReportListOptions>) { }
}
