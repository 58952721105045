import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { DEFAULT_AMOUNT_FORMAT } from '@WebUi/helpers/amount';

@Pipe({
  name: 'bridgeAmount',
})
export class BridgeAmountPipe extends DecimalPipe implements PipeTransform {

  override transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  override transform(value: string | null, digitsInfo?: string, locale?: string): string | null;
  override transform(value: number | null, digitsInfo?: string, locale?: string): string | null;
  override transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  override transform(value: number | string | null | undefined, digitsInfo = DEFAULT_AMOUNT_FORMAT, locale?: string): string | null {
    if (value === null || value === undefined) {
      return null;
    }

    return super.transform(value, digitsInfo, locale);
  }

}
