import { CompanyInfo, ID, UserCompany } from '@Libs/model';
import { TvSettings } from '@WebUi/su/models/su.model';

export interface SuStateModel {
  activeSuCompanyId: ID | null;
  allCompanies: CompanyInfo[];
  observedCompanies: UserCompany[];
  tvSettings: TvSettings;
}

export const SU_STATE_DEFAULTS: SuStateModel = {
  activeSuCompanyId: null,
  allCompanies: [],
  observedCompanies: [],
  tvSettings: {
    autoplay: true,
  },
};
