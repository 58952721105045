import { Injectable } from '@angular/core';
import { CompleteCompanyConnection, CreateCompanyConnection, GetUserCompanies, SetActiveCompany } from '@WebUi/dashboard/store/dashboard.actions';
import { PublicCompany, SalesChannel } from '@Libs/model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ToastsService } from '@Libs/toasts';
import { OnboardingShopifyToken } from '@WebUi/onboarding/models/onboarding.model';
import { ResetOnboardingShopifyToken } from '@WebUi/onboarding/store/onboarding.actions';

@Injectable({
  providedIn: 'root',
})
export class ShopifyService {

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private toastsService: ToastsService,
  ) { }

  createCompanyConnection(
    company: PublicCompany,
    shopifyToken: OnboardingShopifyToken,
  ): Observable<unknown> {
    return this.store.dispatch([new CreateCompanyConnection(
      company.id,
      shopifyToken.shop,
      SalesChannel.SHOPIFY,
      {},
      shopifyToken.connectionToken,
    )])
      .pipe(
        tap({
          next: () => {
            this.translateService.get(
              'Toasts.Onboarding.CompleteCompanyConnection.Store.Successful',
              {
                store: shopifyToken.shop,
                company: company.name,
              },
            )
              .subscribe((translation: string | any) => {
                this.toastsService.success({
                  heading: translation,
                  autoclose: true,
                });
              });
          },
          error: () => {
            this.translateService.get(
              'Toasts.Onboarding.CompleteCompanyConnection.Store.Errored',
              {
                store: shopifyToken.shop,
                company: company.name,
              },
            )
              .subscribe((translation: string | any) => {
                this.toastsService.error({
                  heading: translation,
                  autoclose: false,
                });
              });
          },
        }),
        switchMap(() => this.store.dispatch([
          new GetUserCompanies(),
          new SetActiveCompany(company.id),
          new ResetOnboardingShopifyToken(),
        ])),
      );
  }

  completeCompanyConnection(
    company: PublicCompany,
    shopifyToken: OnboardingShopifyToken,
  ): Observable<unknown> {
    return this.store.dispatch([new CompleteCompanyConnection(
      company.id,
      shopifyToken.connectionToken,
      shopifyToken.shop,
    )])
      .pipe(
        tap({
          next: () => {
            this.translateService.get(
              'Toasts.Onboarding.CompleteCompanyConnection.Store.Successful',
              {
                store: shopifyToken.shop,
                company: company.name,
              },
            )
              .subscribe((translation: string | any) => {
                this.toastsService.success({
                  heading: translation,
                  autoclose: true,
                });
              });
          },
          error: () => {
            this.translateService.get(
              'Toasts.Onboarding.CompleteCompanyConnection.Store.Errored',
              {
                store: shopifyToken.shop,
                company: company.name,
              },
            )
              .subscribe((translation: string | any) => {
                this.toastsService.error({
                  heading: translation,
                  autoclose: false,
                });
              });
          },
        }),
        switchMap(() => this.store.dispatch([
          new GetUserCompanies(),
          new SetActiveCompany(company.id),
          new ResetOnboardingShopifyToken(),
        ])),
      );
  }

}
