import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[aebTranslatedElement]',
})
export class TranslatedElementDirective {

  @Input('aebTranslatedElement') public elementKey!: string;

  constructor(
    public readonly viewRef: ViewContainerRef,
    public readonly templateRef: TemplateRef<any>,
  ) { }

}
