import { ExternalSystemToConnect, ExternalSystemToConnectIntention } from '@WebUi/connections/models/connections.model';

export interface ConnectionsStateModel {
  externalSystemToConnect: ExternalSystemToConnect | null;
  externalSystemToConnectIntention: ExternalSystemToConnectIntention | null;
}

export const CONNECTIONS_STATE_DEFAULTS: ConnectionsStateModel = {
  externalSystemToConnect: null,
  externalSystemToConnectIntention: null,
};
