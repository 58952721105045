import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { CompanyConnection, ID, UserCompany } from '@Libs/model';
import { ActiveCompanyService } from '@WebUi/dashboard/services/active-company.service';

@Injectable({
  providedIn: 'root',
})
export class UserActiveCompanyService extends ActiveCompanyService {

  @Select(DashboardState.activeUserCompanyId) readonly activeCompanyId$!: Observable<ID | null>;
  @Select(DashboardState.activeUserCompany) readonly activeCompany$!: Observable<UserCompany | null>;
  @Select(DashboardState.activeUserCompanyConnections) readonly activeCompanyConnections$!: Observable<CompanyConnection[] | null>;
  @Select(DashboardState.activeUserCompanyHasConnections) readonly activeCompanyHasConnections$!: Observable<boolean | null>;
  @Select(DashboardState.activeUserCompanyNotBrokenConnections) readonly activeCompanyNotBrokenConnections$!: Observable<CompanyConnection[] | null>;
  @Select(DashboardState.activeUserCompanyHasNotBrokenConnections) readonly activeCompanyHasNotBrokenConnections$!: Observable<boolean | null>;
  @Select(DashboardState.activeUserCompanyBrokenConnections) readonly activeCompanyBrokenConnections$!: Observable<CompanyConnection[] | null>;
  @Select(DashboardState.activeUserCompanyHasBrokenConnections) readonly activeCompanyHasBrokenConnections$!: Observable<boolean | null>;
  @Select(DashboardState.activeUserCompanyAccountingSystemConnection) readonly activeCompanyAccountingSystemConnection$!: Observable<CompanyConnection | null>;

}
