import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DEFAULT_DATE_TIME_FORMAT } from '@WebUi/helpers/date';

@Pipe({
  name: 'bridgeDateTime',
})
export class BridgeDateTimePipe extends DatePipe implements PipeTransform {

  override transform(value: Date | string | number, timezone?: string, format?: string, locale?: string): string | null;
  override transform(value: null | undefined, timezone?: string, format?: string, locale?: string): null;
  override transform(value: Date | string | number | null | undefined, timezone?: string, format?: string, locale?: string): string | null;
  override transform(value: Date | string | number | null | undefined, timezone?: string, format = DEFAULT_DATE_TIME_FORMAT, locale?: string): string | null {
    if (!value) {
      return null;
    }

    let valueToTransform = value;

    if (typeof valueToTransform === 'number') {
      // Convert seconds to milliseconds
      valueToTransform *= 1000;
    }

    return super.transform(value, format, timezone, locale);
  }

}
