import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bridgeCurrency',
})
export class BridgeCurrencyPipe implements PipeTransform {

  transform(value: number): string | null {
    return `${this.formatter(value)} kr`;
  }

  // Copied from test.unimcro.no
  private formatter(value: number) {
    const stringIntegerDecimal: string[] = value.toFixed(2).split('.');

    const integer: string = stringIntegerDecimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimal: string = stringIntegerDecimal[1];

    return decimal ? `${integer},${decimal}` : integer;
  }

}
