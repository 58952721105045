import { gql } from 'apollo-angular';
import { ID } from '../common';
import { UserCompany } from '../user';
import { CompanyInfoFragment, UserCompanyFragment } from './fragments';
import { CompanyInfo } from '../company';

export const GET_ALL_COMPANIES_AS_SU = gql`
  query GetAllCompaniesAsSu {
    companies {
      ...CompanyInfoFragment
    }
  }
  ${CompanyInfoFragment}
`;

export type GetAllCompaniesAsSuResponse = {
  companies: CompanyInfo[];
}

export const GET_USER_COMPANY_AS_SU = gql`
  query GetUserCompanyAsSu($companyId: ID!) {
    superUser {
      company(id: $companyId) {
        ...UserCompanyFragment
      }
    }
  }
  ${UserCompanyFragment}
`;

export type GetUserCompanyAsSuResponse = {
  superUser: {
    company: UserCompany;
  };
}

export type GetUserCompanyAsSuResponseVariables = {
  companyId: ID;
}
