import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SETUP_WIZARD_STATE_DEFAULTS, SetupWizardStateModel } from '@WebUi/setup-wizard/models/setup-wizard-state.model';
import { Injectable } from '@angular/core';
import {
  SETUP_WIZARD_SALES_CHANNEL_FLOW_STATE_DEFAULTS,
  SETUP_WIZARD_PAYMENT_VENDOR_FLOW_STATE_DEFAULTS,
  SetupWizardFlowState,
  SetupWizardOnboardingSnapshot,
  SetupWizardSalesChannelFlowState,
  SetupWizardPaymentProviderFlowState,
  isPaymentProviderFlowSnapshot,
  isSalesChannelFlowSnapshot,
  isUnimicroNettbutikkFlowSnapshot,
} from '@WebUi/setup-wizard/models/setup-wizard.model';
import {
  ResetSetupWizard,
  PrepareSetupWizardStateWithOnboardingSnapshot,
  StoreSetupWizardSalesChannelFlowState,
  StoreSetupWizardPaymentProviderFlowState,
} from '@WebUi/setup-wizard/store/setup-wizard.actions';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { CompanyConnection, UserCompany } from '@Libs/model';
import { OnboardingOption } from '@WebUi/onboarding/models/onboarding.model';

@State<SetupWizardStateModel>({
  name: 'setup_wizard',
  defaults: SETUP_WIZARD_STATE_DEFAULTS,
})
@Injectable({
  providedIn: 'root',
})
export class SetupWizardState {

  @Selector([SetupWizardState])
  static onboardingSnapshot(state: SetupWizardStateModel): SetupWizardOnboardingSnapshot | null {
    return state.onboardingSnapshot;
  }

  @Selector([SetupWizardState])
  static flowState(state: SetupWizardStateModel): SetupWizardFlowState | null {
    return state.flowState;
  }

  @Selector([SetupWizardState.onboardingSnapshot, DashboardState.userCompanies])
  static company(onboardingSnapshot: SetupWizardOnboardingSnapshot | null, userCompanies: UserCompany[]): UserCompany | null {
    if (!onboardingSnapshot) {
      return null;
    }

    return userCompanies.find((userCompany: UserCompany) => userCompany.id === onboardingSnapshot.companyId) ?? null;
  }

  @Selector([SetupWizardState.onboardingSnapshot, SetupWizardState.company])
  static accountingSystem(onboardingSnapshot: SetupWizardOnboardingSnapshot | null, userCompany: UserCompany | null): CompanyConnection | null {
    if (!onboardingSnapshot) {
      return null;
    }

    if (!userCompany) {
      return null;
    }

    return userCompany.connections.find((connection: CompanyConnection) => connection.id === onboardingSnapshot.accountingSystemConnectionId) ?? null;
  }

  @Selector([SetupWizardState.onboardingSnapshot, SetupWizardState.company])
  static salesChannel(onboardingSnapshot: SetupWizardOnboardingSnapshot | null, userCompany: UserCompany | null): CompanyConnection | null {
    if (!onboardingSnapshot) {
      return null;
    }

    if (!userCompany) {
      return null;
    }

    if (!isSalesChannelFlowSnapshot(onboardingSnapshot)) {
      return null;
    }

    if (isUnimicroNettbutikkFlowSnapshot(onboardingSnapshot)) {
      return null;
    }

    return userCompany.connections.find((connection: CompanyConnection) => connection.id === onboardingSnapshot.salesChannelConnectionId) ?? null;
  }

  @Selector([SetupWizardState.accountingSystem, SetupWizardState.salesChannel])
  static canProceedSetupWizard(onboardingAccountingSystem: CompanyConnection | null, onboardingSalesChannel: CompanyConnection | null): boolean {
    return !!onboardingAccountingSystem && !!onboardingSalesChannel;
  }

  @Action(PrepareSetupWizardStateWithOnboardingSnapshot)
  prepareSetupWizardStateWithOnboardingSnapshot(ctx: StateContext<SetupWizardStateModel>, payload: PrepareSetupWizardStateWithOnboardingSnapshot): void {
    switch (payload.setupWizardOnboardingSnapshot.option) {
      case OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_ESTORE:
        ctx.patchState({
          onboardingSnapshot: payload.setupWizardOnboardingSnapshot,
          flowState: SETUP_WIZARD_SALES_CHANNEL_FLOW_STATE_DEFAULTS,
        });

        break;
      case OnboardingOption.AUTOMATIC_ACCOUNTING_FOR_PAYMENT_PROVIDER:
        ctx.patchState({
          onboardingSnapshot: payload.setupWizardOnboardingSnapshot,
          flowState: {
            ...SETUP_WIZARD_PAYMENT_VENDOR_FLOW_STATE_DEFAULTS,
          },
        });

        break;
    }
  }

  @Action(StoreSetupWizardSalesChannelFlowState)
  storeSetupWizardSalesChannelFlowState(ctx: StateContext<SetupWizardStateModel>, payload: StoreSetupWizardSalesChannelFlowState): void {
    const state: SetupWizardStateModel = ctx.getState();

    if (!state.onboardingSnapshot) {
      return;
    }

    if (!isSalesChannelFlowSnapshot(state.onboardingSnapshot)) {
      return;
    }

    ctx.setState({
      onboardingSnapshot: state.onboardingSnapshot,
      flowState: {
        step1: payload.flowState.step1 ? payload.flowState.step1 : (state.flowState as SetupWizardSalesChannelFlowState).step1,
        step2: payload.flowState.step2 ? payload.flowState.step2 : (state.flowState as SetupWizardSalesChannelFlowState).step2,
        step3: payload.flowState.step3 ? payload.flowState.step3 : (state.flowState as SetupWizardSalesChannelFlowState).step3,
      },
    });
  }

  @Action(StoreSetupWizardPaymentProviderFlowState)
  storeSetupWizardPaymentProviderFlowState(ctx: StateContext<SetupWizardStateModel>, payload: StoreSetupWizardPaymentProviderFlowState): void {
    const state: SetupWizardStateModel = ctx.getState();

    if (!state.onboardingSnapshot) {
      return;
    }

    if (!isPaymentProviderFlowSnapshot(state.onboardingSnapshot)) {
      return;
    }

    ctx.setState({
      onboardingSnapshot: state.onboardingSnapshot,
      flowState: {
        step1: payload.flowState.step1 ? payload.flowState.step1 : (state.flowState as SetupWizardPaymentProviderFlowState).step1,
        step2: payload.flowState.step2 ? payload.flowState.step2 : (state.flowState as SetupWizardPaymentProviderFlowState).step2,
      },
    });
  }

  @Action(ResetSetupWizard)
  resetSetupWizard(ctx: StateContext<SetupWizardStateModel>): void {
    ctx.patchState({
      ...SETUP_WIZARD_STATE_DEFAULTS,
    });
  }

}
