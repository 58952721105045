import { DEFAULT_APP_LANGUAGE, Lang } from '@Libs/model';
import packageInfo from 'package.json';

export interface AppStateModel {
  appVersion: string;
  stateVersion: string;
  lang: Lang;
}

export const APP_STATE_DEFAULTS: AppStateModel = {
  appVersion: packageInfo.version,
  stateVersion: packageInfo.stateVersion,
  lang: DEFAULT_APP_LANGUAGE,
}
