import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeroiconComponent } from './heroicon/heroicon.component';
import { DEFAULT_HEROICONS_MODULE_OPTIONS, HEROICONS_MODULE_OPTIONS_TOKEN } from './heroicons';
import { SvgIconsModule } from '@Libs/svg-icons';

@NgModule({
  declarations: [
    HeroiconComponent,
  ],
  imports: [
    CommonModule,
    SvgIconsModule,
  ],
  exports: [
    HeroiconComponent,
  ],
  providers: [
    {
      provide: HEROICONS_MODULE_OPTIONS_TOKEN,
      useValue: DEFAULT_HEROICONS_MODULE_OPTIONS,
    },
  ],
})
export class HeroiconsModule { }
