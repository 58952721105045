import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DashboardState } from '@WebUi/dashboard/store/dashboard.state';
import { map, take } from 'rxjs/operators';
import { PublicCompany, ID, UserCompany } from '@Libs/model';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {

  @Select(DashboardState.userCompanies) readonly userCompanies$!: Observable<UserCompany[]>;

  @Select(DashboardState.hasUserCompanies) readonly hasUserCompanies$!: Observable<boolean>;

  private searchCompanyByCompanyIdPredicate(companyId: ID): (company: PublicCompany) => boolean {
    return (company: PublicCompany) => {
      return company.id === companyId;
    };
  }

  getUserCompanyByCompanyId$(companyId: ID): Observable<UserCompany | undefined> {
    return this.userCompanies$
      .pipe(
        map((userCompanies: UserCompany[]) => userCompanies.find(this.searchCompanyByCompanyIdPredicate(companyId))),
        take(1),
      );
  }

  isCompanyBelongsToUserByCompanyId$(companyId: ID): Observable<boolean> {
    return this.userCompanies$
      .pipe(
        map((userCompanies: UserCompany[]) => userCompanies.some(this.searchCompanyByCompanyIdPredicate(companyId))),
        take(1),
      );
  }

}
