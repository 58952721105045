import { environment } from '@WebUi/env';
import { AccountingSystem, UnimicroPlatformDomain } from '@Libs/model';
import { IdTokenClaims } from '@azure/msal-common';

export type Claims = IdTokenClaims & {
  email?: string;
  name?: string;
  acr?: string; // custom policy name
  groups?: string[];
  originalIdp?: UnimicroPlatformDomain | typeof GODS_DOMAIN;
  idp_access_token?: string; // access token for the originalIdp
}

export const GODS_DOMAIN = 'uni-micro-as';
export const GODS_GROUP = 'AeCB Gods';

export interface MsalApiConfig {
  scopes: string[];
  uri: string;
}

export interface UserFlowConfig {
  name: string;
  authority: string;
}

export interface UserFlowsConfig {
  [key: string]: UserFlowConfig;
}

export const DEFAULT_USER_FLOW: UserFlowConfig = environment.azure.userFlows.signUpSignIn;

export const DEFAULT_AUTHORITY: string = DEFAULT_USER_FLOW.authority;

export const USER_FLOW_FOR_SU: UserFlowConfig = environment.azure.userFlows.signUpSignInUniMicroAS;

export const AUTHORITY_FOR_SU: string = USER_FLOW_FOR_SU.authority;

export const USER_FLOW_FOR_ONBOARDING: UserFlowConfig = environment.azure.userFlows.signUpSignInOnboarding;

export const AUTHORITY_FOR_ONBOARDING: string = USER_FLOW_FOR_ONBOARDING.authority;

/**
* Enter here the coordinates of your web API and scopes for access token request
* The current application coordinates were pre-registered in a B2C tenant.
*/
export const API_CONFIGS: MsalApiConfig[] = [
  {
    scopes: ['openid', 'profile', environment.azure.clientId],
    uri: environment.gateway,
  },
];

export const SCOPES: string[] = API_CONFIGS
  .map((apiConfig: MsalApiConfig) => apiConfig.scopes)
  .reduce((accumulator, currentValue) => accumulator.concat(currentValue), []);

export interface AccountingSystemData {
  systemUrl: string;
}

export interface AccountingSystemsData {
  unimicro: AccountingSystemData;
  dnbRegnskap: AccountingSystemData;
  srBankRegnskap: AccountingSystemData;
  eikaRegnskap: AccountingSystemData;
  testUnimicro: AccountingSystemData;
}

export const ACCOUNTING_SYSTEMS_DATA = environment.accountingSystems as AccountingSystemsData;

export const ACCOUNTING_SYSTEM_DATA_MAP: Record<AccountingSystem, AccountingSystemData> = {
  [AccountingSystem.UNIMICRO]: ACCOUNTING_SYSTEMS_DATA.unimicro,
  [AccountingSystem.DNB_REGNSKAP]: ACCOUNTING_SYSTEMS_DATA.dnbRegnskap,
  [AccountingSystem.SPARE_BANK_1_REGNSKAP]: ACCOUNTING_SYSTEMS_DATA.srBankRegnskap,
  [AccountingSystem.EIKA_REGNSKAP]: ACCOUNTING_SYSTEMS_DATA.eikaRegnskap,
  [AccountingSystem.TEST_UNIMICRO]: ACCOUNTING_SYSTEMS_DATA.testUnimicro,
};

