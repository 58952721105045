import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@WebUi/env';

export function createApollo(httpLink: HttpLink): NamedOptions {
  return {
    companyManager: {
      link: httpLink.create({
        uri: `${environment.companyManagerService.private}/graphql`,
      }),
      cache: new InMemoryCache({
        addTypename: false,
      }),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
        mutate: {
          fetchPolicy: 'no-cache',
        },
      },
    },
  };
}

@NgModule({
  imports: [
    ApolloModule,
  ],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [
        HttpLink,
      ],
    },
  ],
})
export class GraphQLModule { }
