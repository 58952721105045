import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CompanyConnection, Agreement, UserCompany } from '@Libs/model';
import { OnboardingState } from '@WebUi/onboarding/store/onboarding.state';
import { OnboardingInput, OnboardingFlowState } from '@WebUi/onboarding/models/onboarding.model';
import { OnboardingStateModel } from '@WebUi/onboarding/models/onboarding-state.model';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {

  @Select(OnboardingState) readonly state$!: Observable<OnboardingStateModel>;

  @Select(OnboardingState.input) readonly input$!: Observable<OnboardingInput>;

  @Select(OnboardingState.agreement) readonly agreement$!: Observable<Agreement | null>;

  @Select(OnboardingState.flowState) readonly flowState$!: Observable<OnboardingFlowState | null>;

  @Select(OnboardingState.company) readonly company$!: Observable<UserCompany | null>;

  @Select(OnboardingState.accountingSystemConnection) readonly accountingSystemConnection$!: Observable<CompanyConnection | null>;

}
