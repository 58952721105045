import { Directive, HostListener, Input, OnInit } from '@angular/core';

export enum ShowHidePasswordState {
  VISIBLE = 0,
  HIDDEN = 1,
}

@Directive({
  selector: '[aebShowHidePassword]',
  exportAs: 'showHidePassword',
})
export class ShowHidePasswordDirective implements OnInit {

  @Input() elementToControl!: HTMLInputElement;
  @Input() initialState: ShowHidePasswordState = ShowHidePasswordState.HIDDEN;

  private _state: ShowHidePasswordState = ShowHidePasswordState.HIDDEN;

  get state(): ShowHidePasswordState {
    return this._state;
  }

  ngOnInit(): void {
    this.setState(this.initialState);
  }

  setState(newState: ShowHidePasswordState): void {
    this._state = newState;

    if (this._state === ShowHidePasswordState.HIDDEN) {
      this.elementToControl.type = 'password';
    } else {
      this.elementToControl.type = 'text';
    }
  }

  @HostListener('click')
  onClick(): void {
    this.setState(this._state === ShowHidePasswordState.HIDDEN ? ShowHidePasswordState.VISIBLE : ShowHidePasswordState.HIDDEN);
  }

}
